import React from 'react';
import InvitePanel from './InvitePanel';
import BodyContainer from '../layout/BodyContainer';

const BulkInvite = () => {
  return (
    <BodyContainer>
      <InvitePanel />
    </BodyContainer>
  );
};
export default BulkInvite;
