import statusApi from '../../api/status.api';

import { GET_USER_GROUP, UPDATE_SELECTED_DEALER } from '../types';

export const getUserGroups = () => async (dispatch, getState) => {
  let payload;
  if (
    getState &&
    getState().currentUser &&
    getState().currentUser.userGroup &&
    Object.hasOwnProperty.call(
      getState().currentUser.userGroup,
      'userHasAdminWriteAccess'
    )
  ) {
    payload = getState().currentUser.userGroup;
  } else {
    payload = await statusApi.getUserGroups();
  }
  dispatch({
    type: GET_USER_GROUP,
    payload,
  });
};

export const updatedSelectedDealer = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_DEALER,
    payload: data
  })
}