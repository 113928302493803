import React from "react";

function IconVerticalMenu(props) {

    let color = props.color ? props.color : "";
    let height = props.height ? props.height : "20";

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 24 24"
            version="1.1"
            viewBox="0 0 24 24"
            xmlSpace="preserve"
            height={height}
        >
            <path fill={color} d="M11.987 24.003c-1.861 0-3.375-1.514-3.375-3.375s1.514-3.375 3.375-3.375 3.375 1.514 3.375 3.375-1.514 3.375-3.375 3.375zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875-.841-1.875-1.875-1.875zM11.987 6.753c-1.861 0-3.375-1.514-3.375-3.375S10.126.003 11.987.003s3.375 1.514 3.375 3.375-1.514 3.375-3.375 3.375zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875-.841-1.875-1.875-1.875zM11.987 15.378a3.379 3.379 0 01-3.375-3.375c0-1.861 1.514-3.375 3.375-3.375s3.375 1.514 3.375 3.375a3.379 3.379 0 01-3.375 3.375zm0-5.25c-1.034 0-1.875.841-1.875 1.875s.841 1.875 1.875 1.875 1.875-.841 1.875-1.875-.841-1.875-1.875-1.875z"></path>
        </svg>
    );
}

export default IconVerticalMenu;
