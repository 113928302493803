import React from 'react';
import { useHistory, Route } from 'react-router-dom';
import { useAuthContext } from '../../Auth';
import { useOktaAuth } from '@okta/okta-react';

const RequireAuth = ({ children, requiredGroups }) => {
  const { isUserInAnyGroups } = useAuthContext();
  const { authState } = useOktaAuth();
  const history = useHistory();
  
  if (!authState.isAuthenticated) {
    if (!authState.isPending) {
      // const fromUri = history.createHref(history.location);
      history.push("/login"); // TODO: Support redirect back to fromUri 
    }
    return null;
  }
  else if (!isUserInAnyGroups(requiredGroups)) {
    if (!authState.isPending) {
      return <h3>Unauthorized</h3>
    }
    return null;

  }

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );

};

const RouteWhenMemberOfAny = ({ component, ...props }) => {

  const PassedComponent = component || function () { return null; };
  const WrappedComponent = (wrappedProps) => (<RequireAuth requiredGroups={props.groups}><PassedComponent {...wrappedProps} /></RequireAuth>);
  return (
    <Route
      {...props}
      render={(routeProps) => props.render ? props.render({ ...routeProps, component: WrappedComponent }) : <WrappedComponent {...routeProps} />}
    />
  );
};

export default RouteWhenMemberOfAny;
