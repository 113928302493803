import React from "react";

function IconPhone(props) {

    let color = props.color ? props.color : "";
    let height = props.height ? props.height : "20";

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 24 24"
            version="1.1"
            viewBox="0 0 24 24"
            xmlSpace="preserve"
            height={height}
        >
            <path fill={color} d="M18.023 24.039a6.293 6.293 0 01-3.411-1.01A50.484 50.484 0 01.993 9.406c-1.606-2.524-1.259-5.747.84-7.846l.774-.774A2.58 2.58 0 014.442.028c.694 0 1.345.269 1.834.758l3.262 3.266a2.597 2.597 0 01-.002 3.667 1.098 1.098 0 00.001 1.549l5.232 5.233c.193.192.468.306.76.306s.567-.114.773-.32c.49-.489 1.141-.759 1.835-.759s1.345.269 1.835.758l3.261 3.26a2.598 2.598 0 010 3.669l-.774.774a6.24 6.24 0 01-4.436 1.85zm-2.581-2.26c.798.5 1.683.757 2.583.757 1.267 0 2.464-.5 3.372-1.408l.774-.774a1.098 1.098 0 000-1.549l-3.26-3.259c-.206-.206-.481-.319-.774-.319s-.568.114-.774.32a2.578 2.578 0 01-1.834.759 2.57 2.57 0 01-1.824-.75l-5.228-5.229a2.598 2.598 0 010-3.669 1.096 1.096 0 00.001-1.545l-.027-.028-3.236-3.239a1.09 1.09 0 00-.773-.318c-.293 0-.568.114-.775.32l-.774.773a4.756 4.756 0 00-.653 5.951 48.97 48.97 0 0013.202 13.207z"></path>
        </svg>
    );
}

export default IconPhone;
