import api from '.';

const REPORT_URL = `/admin`;

const getInvitations = async (params, dispatch) => {
  return api.get(
    `${REPORT_URL}/invitations`,
    params
  );
};

const requestInvitation = async (data, dispatch) => {
  return api.post(
    `${REPORT_URL}/invitation`,
    null,
    data
  );
};

const sendBulkInvitation = async (data, dispatch) => {
  return api.post(
    `${REPORT_URL}/bulkInvitation`,
    null,
    data
  );
};

const getBulkInvitation = async (params, dispatch) => {
  return api.get(
    `${REPORT_URL}/bulkInvitations`,
    params
  );
};

const getBulkInvitationStatus = async (params, dispatch) => {
  return api.get(
    `${REPORT_URL}/bulkInvitationStatus`,
    params
  );
};

export default {
  getInvitations,
  requestInvitation,
  sendBulkInvitation,
  getBulkInvitation,
  getBulkInvitationStatus,
};
