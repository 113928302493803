import api from '.';

const getDocuments = async (carId, userId, params) => {
  try {
    const data = await api.get(`/admin/cars/${carId}/user/${userId}/documents`, params);
    return data;
  } catch (err) {
    console.error('getDocuments', err);
  }
};

const saveDocument = async (carId, userId, dealerId, docData) => {
  try {
    const data = await api.post(`/admin/cars/${carId}/user/${userId}/documents?dealerId=${dealerId}`, {}, docData);
    return data;
  } catch (err) {
    console.error('saveDocument', err);
  }
};

const deleteDocument = async (carId, userId, documentId) => {
  
  try {
    const data = await api.del(`/admin/cars/${carId}/user/${userId}/documents/${documentId}`);
    return data;
  } catch (err) {
    console.error('deleteDocument', err);
    throw(err)
  }
}

export default {
  getDocuments,
  saveDocument,
  deleteDocument
}
