import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

import SideBar from "./components/layout/SideBar";
import Content from "./components/layout/Content";
import TopBar from "./components/layout/TopBar";
import ProfileMenu from "./components/layout/ProfileMenu";
import { AuthProvider } from './Auth';

import './App.css';
import { useHistory, useLocation } from 'react-router-dom';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import blue from '@material-ui/core/colors/blue';
import { green, red } from '@material-ui/core/colors';

const theme = createTheme({
  palette: {
    primary: blue,
    success: green,
    danger:red
  },
});

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}



function App() {
  const query = useQuery();
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  const [profileMenuIsOpen, setProfileMenuOpen] = useState(false);
  const toggleProfileMenu = () => setProfileMenuOpen(!profileMenuIsOpen);

  const [notificationsMenuIsOpen, setNotificationsMenuOpen] = useState(false);
  const toggleNotificationsMenu = () => setNotificationsMenuOpen(!notificationsMenuIsOpen);

  const [oktaAuth, setOktaAuth] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const onAuthRequired = () => {
    history.push('/login');
  };

  useEffect(() => {
    const oktaConfig = {
      issuer: process.env.REACT_APP_OKTA_URL || '',
      clientId: process.env.REACT_APP_OKTA_CLIENT_ID || '',
      redirectUri: process.env.REACT_APP_OKTA_REDIRECT || ''
    };
    setOktaAuth(new OktaAuth(oktaConfig));

  }, [dispatch]);

  const excludeLocations = ['/login', '/login-support', '/activate'];
  const isActivate = excludeLocations.some(function (loc) { return location.pathname.startsWith(loc); });
  const isFullScreen = excludeLocations.includes(location.pathname) || isActivate;
  const showHeaderOrSidebar =  query.get("from") != "autohub" 

  return (
    <div className="App w-100">
      {oktaAuth && <Security
        oktaAuth={oktaAuth}
        onAuthRequired={onAuthRequired}
      >
        <AuthProvider>

          {showHeaderOrSidebar && <TopBar
            toggleSidebar={toggleSidebar}
            toggleProfileMenu={toggleProfileMenu}
            excludeLocations={excludeLocations}
            toggleNotificationsMenu={toggleNotificationsMenu}
          />}
          <ProfileMenu
            toggle={toggleProfileMenu}
            isOpen={profileMenuIsOpen}
            excludeLocations={excludeLocations}
          />
          <main className={isFullScreen ? "full-screen" : "d-flex align-items-stretch"} >
            {showHeaderOrSidebar &&
              <SideBar
                toggle={toggleSidebar}
                isOpen={sidebarIsOpen}
                excludeLocations={excludeLocations}
              />
            }
            <ThemeProvider theme={theme}>
              <Content
                toggleSidebar={toggleSidebar}
                sidebarIsOpen={sidebarIsOpen}
                excludeLocations={excludeLocations}
              />
            </ThemeProvider>
          </main>
        </AuthProvider>
      </Security>}
    </div>

  );
}

export default App;
