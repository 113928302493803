import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import OktaSignInWidget from './OktaSignInWidget';
import { useOktaAuth } from '@okta/okta-react';

import '../../scss/login/_login.scss';
import { useTranslation } from 'react-i18next';

function Login() {

  const [authenticated, setAuthenticated] = useState(null);
  const { oktaAuth, authState } = useOktaAuth();
  const t = useTranslation().t;

  useEffect(() => {
    setAuthenticated(authState.isAuthenticated)
  }, [authState.isAuthenticated]);


  const onSuccess = (res) => {
    if (res.status === 'SUCCESS') {
      return oktaAuth.handleLoginRedirect(res.tokens);
    } else {
      // The user can be in another authentication state that requires further action.
      // For more information about these states, see:
      //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
      console.error("Login error", res);
    }
  }

  const onError = (err) => {
    console.error('error logging in', err);
  }

  if (authenticated === null) return null;

  return authenticated ?
    <Redirect to={{ pathname: '/' }} /> :
    <div className="login">
      <div className="login-footer">&copy; {new Date().getFullYear()} {t('Assurant, Inc. All Rights Reserved')}</div>
      <div className="okta-wrapper">
        <OktaSignInWidget
          onSuccess={onSuccess}
          onError={onError}
        />
      </div>
    </div>
};
  

export default Login;
