import { compose, applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducers from './reducers';

function configureStore(initialState) {
  const composeEnhancers =
    (process.env.REACT_APP_ENV === 'dev' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));
  return createStore(reducers, initialState, enhancer);
}

export default configureStore({});