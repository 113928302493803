import api from '.';

const USERS_URL = `/auth/users`;
const DEALER_USERS = '/dealer/users';

const getUser = async () =>
  api.get(`${USERS_URL}`);

const searchUsers = async (params) => 
  api.get(`${DEALER_USERS}`,params);

export default {
    getUser,
    searchUsers
}