import axios from 'axios';
import api from '.';

const getUploadUrl = async (imageData) =>
  api.post('/auth/uploads', {}, imageData);

const uploadToS3 = async (url, file, fileType,setProgress,cancelToken) =>{

  return axios.put(url, file, {
    headers: {
      'Content-Type': fileType,
    },
    cancelToken: (cancelToken)?cancelToken.token:null,
    onUploadProgress:data=>{
      if(setProgress){
        setProgress(Math.round((100 * data.loaded) / data.total))
      }
    }
  });
}
  

export default {
  getUploadUrl,
  uploadToS3,
};
