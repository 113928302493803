let _authFunction = () => { return ""; }

const setAuthFunction = (authFunction) => {
    _authFunction = authFunction;
}

const apiUrl = process.env.REACT_APP_API_URL || '';

const getApiToken = () => {
    let token = _authFunction();
    return token;
}

const post = async (url, params, body, additionalHeaders) => {
    return sendJson(`${apiUrl}${url}`, "POST", params, body, additionalHeaders);
}

const put = async (url, params, body, additionalHeaders) => {
    return sendJson(`${apiUrl}${url}`, "PUT", params, body, additionalHeaders);
}

const del = async (url, params, body, additionalHeaders) => {
    return sendJson(`${apiUrl}${url}`, "DELETE", params, body, additionalHeaders);
}

const sendJson = async (url, method, params, body, additionalHeaders) => {
  // debugger;
    const newUrl = new URL(url);
    if (params) {
        Object.keys(params)
        .filter(key => params[key] != null)
        .forEach(key => newUrl.searchParams.append(key, params[key]));
    }

    const headers = {
        ...additionalHeaders,
        authorization: `Bearer ${getApiToken()}`,
        "content-type": 'application/json'
    };
    const response = await fetch(newUrl, {
        method: method,
        headers: headers,
        body: JSON.stringify(body)
    });
    if (response.ok) {
        return await response.json();
    }
    throw new Error("network error");
}


const get = async (url, params, additionalHeaders) => {
    const newUrl = new URL(`${apiUrl}${url}`);
    if (params) {
        Object.keys(params)
        .filter(key => params[key] != null)
        .forEach(key => newUrl.searchParams.append(key, params[key]));
    }
    
    const headers = {
        ...additionalHeaders,
        authorization: `Bearer ${getApiToken()}`
    };
    const response = await fetch(newUrl, {
        method: "GET",
        headers: headers
    });
    if (response.ok) {
        return await response.json();
    }
    throw new Error("network error");
}

const publicApiGet = async (url, params, additionalHeaders) => {
    const newUrl = new URL(`${apiUrl}${url}`);
    if (params) {
        Object.keys(params)
        .filter(key => params[key] != null)
        .forEach(key => newUrl.searchParams.append(key, params[key]));
    }
    
    const headers = {
        ...additionalHeaders,
    };
    const response = await fetch(newUrl, {
        method: "GET",
        headers: headers
    });
    if (response.ok) {
        return await response.json();
    }
    throw new Error("network error");
}

const abortableGet = (url, params, additionalHeaders) => {
    const controller = new AbortController();
    const newUrl = new URL(`${apiUrl}${url}`);
    if (params) {
        Object.keys(params)
        .filter(key => params[key] != null)
        .forEach(key => newUrl.searchParams.append(key, params[key]));
    }
    
    const headers = {
        ...additionalHeaders,
        authorization: `Bearer ${getApiToken()}`
    };
    const promise = fetch(newUrl, {
        signal: controller.signal,
        method: "GET",
        headers: headers
    });

    return {
        promise,
        controller
    };
}

const suppressApiErrors = () => {};

const initialStatus = {
    isLoading: false,
    isSuccess: false,
    isFailure: false
};

const loadStatus = {
    ...initialStatus,
    isLoading: true
}

const successStatus = {
    ...initialStatus,
    isSuccess: true
};

const failureStatus = {
    ...initialStatus,
    isFailure: true
}

const copyStatus = (status) => ({...status});

export const statusData = {
    initialStatus,
    loadStatus,
    successStatus,
    failureStatus,
    copyStatus
};

const api = {
    setAuthFunction,
    get,
    post,
    put,
    del,
    suppressApiErrors,
    abortableGet,
    statusData,
    publicApiGet
};

export default api;
