import React, { useState, useEffect }  from 'react';
import '../../App.scss';
import { useTranslation } from 'react-i18next';
import {  withRouter } from 'react-router-dom';
import classNames from "classnames";
import statusApi from '../../api/status.api';

function Footer(props) {
  const { t } = useTranslation();
  const [apiVersion, setApiVersion] = useState('');
  const { location, sidebarIsOpen, excludeLocations } = props;
  let isActivate = excludeLocations.some(function (loc) { return location.pathname.startsWith(loc); })
  useEffect(() => {
    const load = async () => {
      const responseVersion = await statusApi.getVersion();
      setApiVersion(responseVersion.apiVersion);
    };
    load();
  },[])

  return (
          !(excludeLocations.includes(location.pathname) || isActivate) ?
          <footer className={classNames("footer", "mt-auto", "p-2", { "is-open": sidebarIsOpen }, "w-100", "text-xs-center", "text-md-right")} >
              <span>{t('Powered by Assurant Global Automotive')}</span>
              {apiVersion !== '' ? (<div>PGA API Version: {apiVersion}</div>) : ''}
          </footer>
          : null
  );
}

export default withRouter(Footer);
