import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Input, FormGroup, Label } from 'reactstrap';
import AlertBox from '../general/AlertBox';
import InputMask from 'react-input-mask';
import { IconEmail, IconPencil, IconPhone, IconUser } from '../../assets/icons';

class SupportForm extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handleSupportTopicChange = this.handleSupportTopicChange.bind(this);
        this.handleSupportDetailsChange = this.handleSupportDetailsChange.bind(this);
        this.showAlertBox = this.showAlertBox.bind(this);
        this.closeAlertBox = this.closeAlertBox.bind(this);

        this.state = {
            name: null,
            emailAddress: null,
            phoneNumber: null,
            supportTopic: 'Login Issue',
            supportDetails: null,
            submitDisabled: true,
            renderSubmitSuccessAlertBox: false,
        };

        this.formRef = null;
    }

    showAlertBox() {
        this.setState({
            renderSubmitSuccessAlertBox: true
        });
    }

    closeAlertBox() {
        this.setState({
            renderSubmitSuccessAlertBox: false
        });
    }

    async onSubmit(event) {
        event.preventDefault();

        const { name, emailAddress, phoneNumber, supportTopic, supportDetails } = this.state;

        const data = {
            name,
            emailAddress,
            phoneNumber,
            supportTopic,
            supportDetails
        };

        console.log("Data", data);

        const response = await fetch(`${process.env.apiUrl || ''}support`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            console.log(response.body || 'Network failure.');
        } else {
            this.showAlertBox();
            this.formRef.reset();
        }
    }

    handleNameChange(event) {
        let { supportDetails, emailAddress } = this.state;

        this.setState({
            name: event.target.value,
            submitDisabled: !(supportDetails && emailAddress && event.target.value)
        });
    }

    handleEmailAddressChange(event) {
        let { supportDetails, name } = this.state;

        this.setState({
            emailAddress: event.target.value,
            submitDisabled: !(supportDetails && name && event.target.value)
        });
    }

    handlePhoneNumberChange(event) {
        this.setState({ phoneNumber: event.target.value });
    }

    handleSupportTopicChange(event) {
        this.setState({ supportTopic: event.target.value });
    }

    handleSupportDetailsChange(event) {
        let { emailAddress, name } = this.state;

        this.setState({
            supportDetails: event.target.value,
            submitDisabled: !(emailAddress && name && event.target.value)
        });
    }

    handleActivateName(event) {
        console.log(event.target);
        this.setState({
            // nameLabelActivated: true
        });
    }

    render() {

        // TODO: form will send email to: AutoReInsPortal@assurant.com

        const { t } = this.props;

        return (
            <div className="support-form mt-4">
                <form className="w-100" onSubmit={this.onSubmit} ref={(el) => this.formRef = el}>
                    <div className="mr-3 ml-3">
                        <FormGroup>
                            <div className="form-icon"><i><IconUser /></i></div>
                            <Input type="text" area-label={t('Name')} onChange={this.handleNameChange} placeholder={t('Name')}/>
                            <label className="floating-label" htmlFor="name">{t('Name*')}</label>
                        </FormGroup>
                        <FormGroup>
                            <div className="form-icon"><i><IconEmail /></i></div>
                            <Input type="email" placeholder={t('Email*')} area-label={t('Email')} onChange={this.handleEmailAddressChange} />
                            <label className="floating-label" htmlFor="email">{t('Email*')}</label>
                        </FormGroup>
                        <FormGroup>
                            <div className="form-icon"><i><IconPhone /></i></div>
                            <InputMask mask="(999) 999-9999" onChange={this.handlePhoneNumberChange}>
                                {(inputProps) => <Input type="tel" {...inputProps} placeholder={t('(xxx) xxx-xxxx')} area-label={t('Phone Number')} onChange={this.handlePhoneNumberChange} />}
                            </InputMask>
                            <label className="floating-label" htmlFor="phone">{t('Phone Number')}</label>
                        </FormGroup>
                        <FormGroup>
                            <Label>What do you need help with?*</Label>
                            <Input type="select" name="select" id="exampleSelect" onChange={this.handleSupportTopicChange}>
                                <option>{t('Login Issue')}</option>
                                <option>{t('New User or Change In Access')}</option>
                                <option>{t('Feature Suggestion')}</option>
                                <option>{t('Other')}</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <div className="form-icon form-icon-textarea"><i><IconPencil /></i></div>
                            <Input type="textarea" placeholder={t('Please provide details (more is better).*')} area-label={t('Proivde Details')} rows="5" onChange={this.handleSupportDetailsChange} />
                        </FormGroup>
                    </div>

                    <div>
                        <Button className="ml-3" color="primary" disabled={this.state.submitDisabled}>{t('Submit')}</Button>
                    </div>
                </form>
                <AlertBox
                    title={t('Success!')}
                    text={t('This form has been successfully submitted.')}
                    onClose={this.closeAlertBox}
                    isOpen={this.state.renderSubmitSuccessAlertBox}
                    isSuccess={true}
                />
            </div>
        );

    }
}

export default withTranslation()(SupportForm);
