
export const DEALERS = [{
  display: 'DIG03585',
  value: 'DIG03585'
}, {
  display: '000TX002',
  value: '000TX002'
}, {
  display: 'ENT-00112',
  value: 'ENT-00112'
}, {
  display: '0000664A',
  value: '0000664A'
}, {
  display: 'FSWRDG001',
  value: 'FSWRDG001'
}, {
  display: 'CA00084',
  value: 'CA00084'
}];

export const DEALERSLIST = [
  {
    dealerName: 'FRANK KENT CADILLAC',
    id: '000TX002'
  }, {
    dealerName: 'COOLEY & COOLEY RECO, LTD. (ESC)',
    id: 'DIG03585'
  }, {
    dealerName: 'BCU',
    id: '0000141G'
  }, {
    dealerName: 'ENTERPRISE CAR SALES - 52R1',
    id: 'ENT-00112'
  }, {
    dealerName: 'NALLEY LEXUS OF MARIETTA',
    id: '0000664A'
  }, {
    dealerName: 'FSW RDG TEST ACCOUNT',
    id: 'FSWRDG001'
  }, {
    dealerName: 'PUTNAM TOYOTA',
    id: 'CA00084'
  }];

export const DEFAULT_TIMEOUT = 5000;
export const PAGINATION_OPTIONS = [10, 25, 50];
export const CSV_FILE_ONLY = 'Please Upload a csv file only.';
export const INCORRECT_CSV = 'The csv file uploaded is not valid';