import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { DataGrid } from '@material-ui/data-grid';
import {
  Button,
  Grid,
  LinearProgress,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { addHours, addMonths, addDays, format, parseISO } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ReactExport from 'react-export-excel';
import { CSVLink } from 'react-csv';
import { PAGINATION_OPTIONS } from '../../constants';
import reportsApi from '../../api/report.api';
import FilterModal from '../general/Filter';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const useStyles = makeStyles({
  root: {
    '& .custom-dark-theme--header': {
      backgroundColor: '#343a40 !important',
      color: 'white !important',
      '& svg': {
        color: 'white !important',
      },
    },
    '& .custom-link-cell': {
      color: 'blue !important',
      cursor: 'pointer',
    },
  },
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const InvitationLogs = (props) => {
  const { filter, jobStatus, getBulkInvitationStatus } = props;
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [status, setStatus] = useState('');
  const [invitationSource, setInvitationSource] = useState('');
  const [invitationIntent, setInvitationIntent] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const dataGridRef = useRef();
  const userHasAdminWriteAccess = useSelector((state) =>
    state.currentUser.userGroup
      ? state.currentUser.userGroup.userHasAdminWriteAccess
      : false
  );

  const columns = [
    {
      headerName: 'Request',
      field: 'request',
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      width: 150,
      renderCell: (e) => {
        try {
          const req = atob(e.value);
          return (
            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {req.startsWith('<env')
                ? req
                : JSON.stringify(JSON.parse(req), null, 2)}
            </div>
          );
        } catch (err) {
          return (
            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {e.value}
            </div>
          );
        }
      },
    },
    {
      headerName: 'Response',
      field: 'response',
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      width: 150,
    },
    {
      headerName: 'Vin',
      field: 'vin',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Email',
      field: 'email',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Invitation Source',
      field: 'invitationSource',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'Invitation Intent',
      field: 'invitationIntent',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'Created At',
      valueFormatter: (e) =>
        e.value ? format(parseISO(e.value), 'MM/dd/yyyy') : '',
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Status',
      renderCell: (e) => {
        return e.value && e.value.toLowerCase() !== 'failure' ? (
          <CheckCircleRoundedIcon style={{ fill: 'green' }} />
        ) : (
          <CancelRoundedIcon style={{ fill: 'red' }} />
        );
      },
      field: 'status',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Reasons',
      field: 'message',
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      width: 150,
      renderCell: () => {
        return 'View';
      },
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadMsg, setDownloadMsg] = useState(null);
  const classes = useStyles();
  const [orignalDataSource, setOrignalDataSource] = useState([]);
  const [isFromBulkInvite, setIsFromBulkInvite] = useState(
    !!(filter && filter.bulkInviteRef)
  );
  const [appliedFilter, setAppliedFilter] = useState(filter || {});
  const previousFilter = usePrevious(appliedFilter);
  const [filterDisplay, setFilterDisplay] = useState(
    filter ? 'Filter Applied' : ''
  );
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;
  const csvLinkRef = useRef();
  const [failedData, setFailedData] = useState([]);

  async function fetchData(
    skip = dataSource.length,
    limit = pageSize,
    pipeLine
  ) {
    const params = {
      skip,
      limit,
      filter: pipeLine
        ? JSON.stringify(pipeLine)
        : JSON.stringify(appliedFilter),
    };

    return reportsApi.getInvitationLogs(params, dispatch);
  }

  function updateDataSource(data) {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._gridId + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _gridId: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  }

  useEffect(() => {
    function checkIfDataPresent() {
      const totalDataExpected = (page + 1) * pageSize;
      const startIndex = page * pageSize;
      return (
        dataSource &&
        dataSource.length > startIndex &&
        (rowCount > totalDataExpected
          ? dataSource.length >= totalDataExpected
          : true)
      );
    }

    const loadData = async (appliedFilterUpdated) => {
      if (!checkIfDataPresent() || appliedFilterUpdated) {
        if (appliedFilterUpdated && page !== 0) {
          setDataSource([]);
          return;
        }
        setIsLoading(true);
        const reports = await fetchData(
          appliedFilterUpdated ? 0 : dataSource.length
        );
        if (reports && reports.data) {
          if (appliedFilterUpdated) {
            setDataSource(
              reports.data.map((item, index) => {
                return { ...item, _gridId: index };
              })
            );
          } else {
            updateDataSource(reports.data);
          }
          if (reports.totalCount !== rowCount) {
            setRowCount(reports.totalCount);
          }
        }
        setIsLoading(false);
      }
    };
    const appliedFilterUpdated =
      JSON.stringify(appliedFilter) !== JSON.stringify(previousFilter);
    loadData(appliedFilterUpdated);
  }, [page, appliedFilter, dispatch]);

  useEffect(() => {
    if (isFromBulkInvite && jobStatus && !(jobStatus._id || jobStatus.error)) {
      if (getBulkInvitationStatus) {
        getBulkInvitationStatus(filter.bulkInviteRef.$eq);
      }
    }
  }, [jobStatus]);

  useEffect(() => {
    if (dataSource.length === 0) {
      setPage(0);
    }
  }, [dataSource]);

  useEffect(() => {
    if (failedData.length) {
      setTimeout(() => {
        csvLinkRef.current.link.click();
      }, 0);
    }
  }, [failedData]);

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];

    let dateDisplay = '';
    if (filterAfterDate) {
      newFilter.createdAt = { $gte: `ISO${filterAfterDate}` };
      dateDisplay = `Dates: ${format(filterAfterDate, 'MM/dd/yyyy')}`;
    }
    if (filterBeforeDate) {
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: `ISO${filterBeforeDate}`,
      };
      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(filterBeforeDate, 'MM/dd/yyyy')}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(
          filterBeforeDate,
          'MM/dd/yyyy'
        )}`;
      }
    }
    if (status) {
      newFilter.status = { $eq: `${status}` };
    }
    if (invitationIntent) {
      newFilter.invitationIntent = { $eq: `${invitationIntent}` };
    }
    if (invitationSource) {
      newFilter.invitationSource = { $eq: `${invitationSource}` };
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setAppliedFilter(newFilter);
    if (displays.length) setFilterDisplay(displays.join(' '));
    else setFilterDisplay('Filter Applied');
  };

  useEffect(() => {
    if (applyQuickFilter && !!filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate]);

  async function fetchDataInBatches(
    index = 0,
    limit = 100,
    allReports,
    pipeLine
  ) {
    const report = await fetchData(index * limit, limit, pipeLine);
    allReports = allReports.concat(report.data); // eslint-disable-line no-param-reassign
    if (report.currentPage !== report.pageCount) {
      setDownloadMsg(
        `Downloading ${report.currentPage + 1} of ${report.pageCount} Pages`
      );
      return fetchDataInBatches(report.currentPage, limit, allReports);
    }
    return allReports;
  }

  const downloadResults = async () => {
    setIsDownloading(true);
    setDownloadData([]);
    setDownloadMsg(`Downloading 1 of ${Math.round(rowCount / 100)} Pages`);
    const reports = await fetchDataInBatches(0, 100, []);
    setDownloadData(reports);
    setIsDownloading(false);
    setDownloadMsg(null);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const setLastDayFilter = () => {
    setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSevenDays = () => {
    setFilterAfterDate(addDays(new Date(), -7));
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    setStatus('');
    setInvitationSource('');
    setInvitationIntent('');
    setAppliedFilter({});
    setPage(0);
    setIsFromBulkInvite(false);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleInvitationSource = (event) => {
    setInvitationSource(event.target.value);
  };

  const handleInvitationIntent = (event) => {
    setInvitationIntent(event.target.value);
  };

  const handleCellClick = (cellParams, event) => {
    if (cellParams.field === 'response') {
      const parsedBody = JSON.parse(cellParams.value);
      setModalBody(JSON.stringify(parsedBody, null, 2));
      if (
        parseInt(parsedBody.statusCode, 10) === 200 &&
        parsedBody.isBase64Encoded &&
        parsedBody.body
      ) {
        setQrCodeData(parsedBody.body);
      }
      setOpenModal(true);
    } else if (cellParams.field === 'request') {
      setModalBody(event.target.textContent);
      setOpenModal(true);
    } else if (cellParams.field === 'message') {
      setModalBody(
        cellParams.row.message && cellParams.row.message.length
          ? cellParams.row.message.join('\n')
          : 'No reason found for this invite.'
      );
      setOpenModal(true);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setQrCodeData(null);
  };

  const applyCustomFilter = (value) => {
    let data = orignalDataSource;
    if (orignalDataSource && !orignalDataSource.length) {
      setOrignalDataSource(dataSource);
      data = dataSource;
    }
    if (data.length === rowCount) {
      if (value && value.length) {
        data = data.filter((item) => {
          return item.status === value;
        });
      }
      setDataSource(data);
    } else {
      const filterValues = { ...appliedFilter };
      if (
        value &&
        value.length &&
        appliedFilter &&
        appliedFilter.status !== value
      ) {
        filterValues.status = value;
      } else {
        delete filterValues.status;
      }
      setAppliedFilter(filterValues);
    }
  };

  const downloadFailedResults = async () => {
    setIsDownloading(true);
    if (dataSource.length === rowCount) {
      setFailedData(
        dataSource.filter((item) => {
          return item.status === 'Failure';
        })
      );
    } else {
      const reports = await fetchDataInBatches(0, 100, [], {
        status: 'Failure',
      });
      setFailedData(reports);
    }
    setIsDownloading(false);
  };

  return (
    <Grid container className="reports">
      <Grid item sm={1} />
      <Grid item sm={10}>
        {isFromBulkInvite && jobStatus.totalRows ? (
          <div>
            <Button variant="contained" onClick={() => applyCustomFilter('')}>
              Total:&nbsp;
              {jobStatus.totalRows}
            </Button>
            <Button
              variant="contained"
              onClick={() => applyCustomFilter('Success')}
              className="btn btn-success"
              style={{
                color: '#fff',
                backgroundColor: '#28a745',
                borderColor: '#28a745',
                margin: '0 10px',
              }}
            >
              Success:&nbsp;
              {jobStatus.success}
            </Button>
            <Button
              variant="contained"
              onClick={() => applyCustomFilter('Failure')}
              className="btn btn-warning"
              style={{
                color: '#212529',
                backgroundColor: '#ffc107',
                borderColor: '#ffc107',
              }}
            >
              Failure:&nbsp;
              {jobStatus.failure}
            </Button>
          </div>
        ) : (
          ''
        )}
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <span className="mr-2">Invitation Logs</span>
          {userHasAdminWriteAccess && (
            <Button
              disabled={isDownloading}
              color="primary"
              variant="outlined"
              onClick={downloadResults}
            >
              {downloadMsg ? (
                <>
                  <span style={{ fontSize: '12px' }}>{downloadMsg}</span>
                  <CircularProgress style={{ height: '25px', width: '25px' }} />
                </>
              ) : (
                'Download details'
              )}
            </Button>
          )}
          &nbsp;
          {isFromBulkInvite && jobStatus.failure ? (
            <Button
              disabled={isDownloading}
              color="primary"
              variant="outlined"
              onClick={downloadFailedResults}
            >
              {isDownloading ? (
                <>
                  <span style={{ fontSize: '12px' }}>Downloading&nbsp;</span>
                  <CircularProgress style={{ height: '25px', width: '25px' }} />
                </>
              ) : (
                'Download Failed Invites'
              )}
            </Button>
          ) : (
            ''
          )}
          <CSVLink filename="report.csv" data={failedData} ref={csvLinkRef} />
          {downloadData && downloadData.length ? (
            <ExcelFile hideElement>
              <ExcelSheet data={downloadData} name="InvitationLogs">
                <ExcelColumn label="request" value="request" />
                <ExcelColumn label="response" value="response" />
                <ExcelColumn label="vin" value="vin" />
                <ExcelColumn label="email" value="email" />
                <ExcelColumn label="phone" value="phone" />
                <ExcelColumn
                  label="invitationSource"
                  value="invitationSource"
                />
                <ExcelColumn
                  label="invitationIntent"
                  value="invitationIntent"
                />
                <ExcelColumn label="createdAt" value="createdAt" />
                <ExcelColumn label="status" value="status" />
              </ExcelSheet>
            </ExcelFile>
          ) : (
            ''
          )}
          <div className="flex-grow-1" />
          {appliedFilter && !Object.keys(appliedFilter).length && (
            <>
              <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
            </>
          )}
          <FilterModal
            currentFilters={appliedFilter}
            onSave={saveFilters}
            itemName="Reports"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk="true"
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk="true"
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel htmlFor="select-status">Status</InputLabel>
                <Select
                  labelId="select-status"
                  id="select"
                  value={status}
                  onChange={handleStatusChange}
                >
                  <MenuItem value="Success">Success</MenuItem>
                  <MenuItem value="Failure">Failure</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel htmlFor="select-invitationSource">
                  Invitation Source
                </InputLabel>
                <Select
                  labelId="select-invitationSource"
                  id="select"
                  value={invitationSource}
                  onChange={handleInvitationSource}
                >
                  <MenuItem value="FORM_SERVICE">FORM SERVICE</MenuItem>
                  <MenuItem value="OPERATIONAL_ACQUISITION">
                    OPERATIONAL ACQUISITION
                  </MenuItem>
                  <MenuItem value="DEALER_JSON">DEALER JSON</MenuItem>
                  <MenuItem value="DEALER_CSV">DEALER CSV</MenuItem>
                  <MenuItem value="COULD_NOT_DETERMINE">
                    COULD NOT DETERMINE
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel htmlFor="select-invitationIntent">
                  Invitation Intent
                </InputLabel>
                <Select
                  labelId="select-invitationIntent"
                  id="select"
                  value={invitationIntent}
                  onChange={handleInvitationIntent}
                >
                  <MenuItem value="INVITATION">INVITATION</MenuItem>
                  <MenuItem value="CONTRACT_UPDATE">CONTRACT UPDATE</MenuItem>
                  <MenuItem value="COULD_NOT_DETERMINE">
                    COULD NOT DETERMINE
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </FilterModal>
        </h2>
        {isLoading ? (
          <LinearProgress style={{ height: '1rem' }} color="secondary" />
        ) : (
          <div
            style={{ height: '70vh', width: '100%' }}
            className={classes.root}
          >
            <DataGrid
              pageSize={pageSize}
              page={page}
              pagination
              rowsPerPageOptions={PAGINATION_OPTIONS}
              onPageSizeChange={handlePageSizeChange}
              rowCount={rowCount}
              getRowId={(row) => row._gridId}
              rows={dataSource}
              columns={columns}
              onPageChange={handlePageChange}
              onCellClick={handleCellClick}
              ref={dataGridRef}
            />
          </div>
        )}
        <Dialog
          onClose={handleModalClose}
          aria-labelledby="customized-dialog-title"
          open={openModal}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleModalClose}>
            Value
          </DialogTitle>
          <DialogContent>
            <pre>{modalBody}</pre>
            {qrCodeData ? (
              <div
                style={{
                  padding: '20px 0px',
                }}
              >
                <div>QR Code:</div>
                <div>
                  <div style={{ textAlign: 'center' }}>
                    <img src={`data:image/png;base64,${qrCodeData}`} alt="" />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default InvitationLogs;
