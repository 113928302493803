import React from "react";

function IconAdd(props) {

  let color = props.color ? props.color : "";
  let height = props.height ? props.height : "20";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height}
    >
      <path fill={color} d="M12 24a.75.75 0 01-.75-.75v-10.5H.75a.75.75 0 010-1.5h10.5V.75a.75.75 0 011.5 0v10.5h10.5a.75.75 0 010 1.5h-10.5v10.5A.75.75 0 0112 24z"></path>
    </svg>
  );
}

export default IconAdd;
