import userApi from '../../api/user.api';

import { GET_USER } from '../types';

export const fetchUser = () => async (dispatch) => {
  const payload = await userApi.getUser();
  dispatch({
    type: GET_USER,
    payload: payload
  })
}