import React, { useState } from 'react';
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  Container,
  Row,
  Spinner,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { CSV_FILE_ONLY } from '../../constants';
import fileUploadApi from '../../api/fileUpload.api';
import invitationApi from '../../api/invitation.api';

const validator = {
  email: (value) => {
    return value
      ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
      : true;
  },
  phoneNumber: (value) => value && /^(\+1)?([0-9]{10})$/.test(value),
  vin: (value) => value && value.length && /^[A-Z0-9]{17}$/.test(value),
};

const NO_DATA_IN_CSV = 'No data found in the uploaded csv.';
const COLUMNS_MISMATCH = 'Uploaded csv does not contain the required columns.';

const BulkInvitationForm = (props) => {
  const { closeDialog, updateListData } = props;
  const [bulkFile, setBulkFile] = useState(null);
  const [validFile, setValidFile] = useState(true);
  const [fileFeedback, setFileFeedback] = useState('');
  const [numberOfRecords, setNumberOfRecords] = useState(0);
  const [sendingInvite, setSendingInvite] = useState(false);
  const dispatch = useDispatch();
  const [formFields, setFormFields] = useState({
    emailChannel: false,
    smsChannel: false,
  });

  const getPayload = (fileUrl) => {
    const fileName = encodeURI(bulkFile.name);
    const regex = new RegExp(
      `(bulk-invitations-jobs/)(.*)(/${fileName})`,
      'gi'
    );
    const matches = regex.exec(fileUrl);
    let filePath = '';
    let jobId = '';
    if (matches && matches.length === 4) {
      [filePath, , jobId] = matches;
    }
    const channels = [];
    if (formFields.emailChannel) {
      channels.push('EMAIL');
    }
    if (formFields.smsChannel) {
      channels.push('SMS');
    }
    return {
      filePath,
      jobId,
      channels,
      dealerId: formFields.dealer,
      totalRows: numberOfRecords,
    };
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setSendingInvite(true);
    const fileUrl = await fileUploadApi.getUploadUrl({
      type: 'bulkInvitation',
      parameters: {},
      imageName: bulkFile ? bulkFile.name : '',
      contentType: bulkFile.type,
    });
    // uploadToS3
    await fileUploadApi.uploadToS3(fileUrl, bulkFile, bulkFile.type);
    const payload = getPayload(fileUrl);
    const response = await invitationApi.sendBulkInvitation(payload, dispatch);
    if (closeDialog) {
      closeDialog();
    }
    if (response && updateListData) {
      updateListData(response);
    }
    setSendingInvite(true);
  };

  const isAllColPresent = (columns) => {
    const inviteColumns = [
      'name',
      'vin',
      'email',
      'phoneNumber',
      'dealerId',
      'address1',
      'address2',
      'city',
      'state',
      'zipCode',
      'country',
      'smsEnabled',
      'emailEnabled',
    ];
    const col = inviteColumns.find((item, index) => {
      return item !== columns[index];
    });
    return !(col && col.length);
  };

  const checkCsvValidaity = (fileData) => {
    if (!fileData || fileData.length === 0) {
      setFileFeedback(NO_DATA_IN_CSV);
      return false;
    }
    const columns = fileData[0].split(',');
    if (!isAllColPresent(columns)) {
      setFileFeedback(COLUMNS_MISMATCH);
      return false;
    }
    const colIndex = columns.reduce((acc, item, index) => {
      acc[item] = index;
      return acc;
    }, {});
    let totalRecords = 0;
    for (let itr = 1; itr < fileData.length; itr++) {
      const row = fileData[itr].split(',');
      if (row && row.length === columns.length) {
        const entry = Object.entries(validator).find(([key, value]) => {
          const index = colIndex[key];
          return !value(row[index]);
        });
        if (entry && entry.length) {
          setFileFeedback(
            `Value: '${row[colIndex[entry[0]]]}' provided for column: '${
              entry[0]
            }' is not valid.`
          );
          return false;
        }
        totalRecords++;
      } else if (row.length === 1 && row[0] === '') {
        // continue
      } else {
        setFileFeedback(
          `Row: '${itr}' does not have values for all the columns.`
        );
        return false;
      }
    }
    if (totalRecords === 0) {
      setFileFeedback(NO_DATA_IN_CSV);
      return false;
    }
    setNumberOfRecords(totalRecords);
    return true;
  };

  const onFileUpload = (event) => {
    if (event && event.target) {
      const fileName =
        event.target.files && event.target.files.length
          ? event.target.files[0].name
          : '';
      if (fileName.split('.').pop() !== 'csv') {
        setValidFile(false);
        setFileFeedback(CSV_FILE_ONLY);
        return;
      }
      const { target } = event;
      const reader = new FileReader();
      reader.onloadend = (e) => {
        if (
          e.target.result &&
          e.target.result.length &&
          checkCsvValidaity(e.target.result.split(/\r?\n/))
        ) {
          setBulkFile(target.files[0]);
          setValidFile(true);
        } else {
          setValidFile(false);
          target.value = '';
        }
      };
      reader.readAsText(event.target.files[0]);
    }
  };

  const handleInputChange = (event) => {
    const { name } = event.target;
    let { value } = event.target;
    const cloneForm = { ...formFields };

    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }
    cloneForm[name] = value;
    setFormFields(cloneForm);
  };

  return (
    <div>
      <div>
        <Container>
          <Row>
            <Col sm="1" />
            <Col sm="10">
              <Form onSubmit={handleFormSubmit} className="invite-form">
                <FormGroup row>
                  <Label for="file" sm={3}>
                    File*
                  </Label>
                  <Col sm={8}>
                    <Input
                      type="file"
                      name="File"
                      id="file"
                      onChange={onFileUpload}
                      invalid={!validFile}
                      accept=".csv"
                      required
                    />
                    <FormText color="muted">
                      Please Upload a csv file only. You can view a sample
                      csv&ensp;
                      <a
                        href="/Bulk-Invitations-Sample.csv"
                        style={{
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          color: 'black',
                        }}
                        download
                      >
                        here.
                      </a>
                    </FormText>
                    <FormFeedback>{fileFeedback}</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="formFields" sm={3}>
                    Invitation Channel
                  </Label>
                  <Col sm={{ size: 2 }}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          id="emailChannel"
                          name="emailChannel"
                          checked={formFields.emailChannel}
                          onChange={handleInputChange}
                        />
                        Email
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col sm={{ size: 2 }}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          id="smsChannel"
                          name="smsChannel"
                          checked={formFields.smsChannel}
                          onChange={handleInputChange}
                        />
                        SMS
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="dealer" sm={3}>
                    Dealer
                  </Label>
                  <Col sm={7}>
                    <Input
                      type="text"
                      name="dealer"
                      id="dealer"
                      value={formFields.dealer || ''}
                      onChange={handleInputChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <div style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    Note: Once your file is processed it&apos;s immediately
                    deleted, we do not store a copy of this file.
                  </div>
                </FormGroup>
                <FormGroup row>
                  <Col sm={3} />
                  <Col sm="auto">
                    <Button disabled={sendingInvite}>
                      {!sendingInvite ? (
                        'Send Invite'
                      ) : (
                        <>
                          <span>Sending Invite&nbsp;</span>
                          <Spinner
                            color="dark"
                            style={{ height: '25px', width: '25px' }}
                          />
                        </>
                      )}
                    </Button>
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            <Col sm="1" />
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BulkInvitationForm;
