import React, { createContext, Component, useContext } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import api from './api';
import statusApi from './api/status.api';

const AuthContext = createContext({
  user: null,
  groups: [],
  isAuthenticated: false,
  isPending: false,
  isUserInAnyGroups: (requiredGroups) => false,
  userGroups: {}
});

export default AuthContext.Consumer;

export const useAuthContext = () => useContext(AuthContext);

export const withAuthContext = (Component) => {
  return (props) => (
    <AuthContext.Consumer>
      {value => <Component {...props} authContext={value} />}
    </AuthContext.Consumer>
  )
}

class AuthController extends Component {
  state = {
    user: null,
    groups: [],
    isAuthenticated: false,
    getAccessToken: () => "",
    isUserInAnyGroups: (requiredGroups) => this.isUserInAnyGroups(requiredGroups),
    userGroups: {}
  }

  componentDidUpdate() {
    this.checkAuthentication();
  }

  componentDidMount() {
    this.checkAuthentication();
  }

  isUserInAnyGroups(requiredGroups) {
    var groups = this.state.groups;
    return !requiredGroups || requiredGroups.length === 0 || requiredGroups.filter(x => groups.includes(x)).length > 0;
  }

  async checkAuthentication() {
    const isAuthenticated = this.props.authState.isAuthenticated;

    if (isAuthenticated !== this.state.isAuthenticated) {
      const user = await this.props.oktaAuth.getUser();
      api.setAuthFunction(this.props.oktaAuth.getAccessToken.bind(this.props.oktaAuth));
      let userGroups;
      try {
        userGroups = await statusApi.getUserGroups();
      } catch (e) {
        // Do nothing for now
        console.log(e);
      }
      this.setState(
        {
          isAuthenticated,
          user,
          groups: user ? user.groups : [],
          getAccessToken: this.props.oktaAuth.getAccessToken.bind(this.props.oktaAuth),
          userGroups
        });
    }
  }

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }

  
}

export const UserTypes = {
  Internal: "Internal",
  External: "External"
}





export const AuthProvider = withOktaAuth(AuthController);
