import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import {useDropzone} from 'react-dropzone'

import Loader from '../general/Loader';
import { sortCaret } from '../general/BootstrapTableSettings';
import { useAuthContext } from '../../Auth';
import documentsApi from '../../api/documents.api';
import { useRouteMatch } from 'react-router-dom';
import { Button, Collapse, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { IconAdd, IconCheckCircle, IconDocument, IconTrash, IconWarning } from '../../assets/icons';
import fileUploadApi from '../../api/fileUpload.api';

import '../../scss/vehicles/_vehicle_documents.scss';

const STATUSES = {
  success: 'SUCCESS',
  uploading: 'UPLOADING',
  staged: 'STAGED',
  error: 'ERROR'
}

const VehicleDocuments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [docList, setDocList] = useState([]);
  const [docsToUpload, setDocsToUpload] = useState([]);
  const [isUploadingDocs, setIsUploadingDocs] = useState(false);
  const [showUploads, setShowUploads] = useState(false);

  const [pendingDeleteId, setPendingDeleteId] = useState(null);

  const t = useTranslation().t;
  const authContext = useAuthContext();
  const match = useRouteMatch("/vehicles/:carId/docs/:userId");

  const onDrop = useCallback(files => {
    setDocsToUpload(p => [...p, ...files.map(f => ({file: f}))]);
  }, []);
  const {getRootProps, getInputProps} = useDropzone({onDrop});

  const loadData = async () => {
    setIsLoading(true);
    const updateDocList = await documentsApi.getDocuments(match.params.carId, match.params.userId);
    setDocList(updateDocList);
    setIsLoading(false);
  };

  useEffect(() => {
    if (authContext.isAuthenticated) {
      loadData();
    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.isAuthenticated, match.params.carId, match.params.userId]);

  const columns = [
    {
      dataField: 'name',
      text: t('Document Name'),
      sort: false,
      title: true,
      sortCaret
    },
    {
      dataField: 'createdAt',
      text: t('Created'),
      sort: false,
      title: true,
      sortCaret,
      type: 'date'
    },
    {
      dataField: 'photos',
      text: t('File'),
      sort: false,
      title: true,
      formatter: (cell, row) => (
        <a href={cell} target="_blank" without rel="noopener noreferrer"><IconDocument color="#000" height="24" /></a>
      )
    },
    {
      dataField: '_id',
      text: '',
      sort: false,
      title: false,
      formatter: (cell, row) => {
        const createdAtTime = new Date(row.createdAt).getTime();
        const now = new Date().getTime();
        const daysInMilliseconds = 10 * 24 * 60 * 60 * 1000; // Ten days in milliseconds

        return (now - createdAtTime) < daysInMilliseconds ? <div onClick={() => openDeleteModal(cell)}><IconTrash color="#000" height="24" /></div> : <></>;
      }
    }
  ];

  const openDeleteModal = (id) => setPendingDeleteId(id);
  const closeDeleteModal = () => setPendingDeleteId(null);
  const deleteDocument = async () => {
    const documentId = pendingDeleteId;

    setPendingDeleteId(null);
    await documentsApi.deleteDocument(match.params.carId, match.params.userId, documentId);
    await loadData();
  }

  const uploadFile = async (file) => {
    const name = file.name;
    const fileUrl = await fileUploadApi.getUploadUrl({
      type: 'virtualGloveBox',
      parameters: {
        carId: match.params.carId
      },
      imageName: name,
      contentType: file.type,
    });
    await fileUploadApi.uploadToS3(fileUrl, file, file.type);
    const fileData = {
      carId: match.params.carId,
      photos: [`${match.params.carId}/virtual-glove-box/${name}`],
      name
    };

    // TODO: Pull the dealer id once we have it
    await documentsApi.saveDocument(match.params.carId, match.params.userId, null, fileData);
  };

  const uploadFiles = async () => {
    setIsUploadingDocs(true);
    const docs = docsToUpload.map(file => ({...file, status: STATUSES.staged}));
    setDocsToUpload(docs);
    for (const docIndex in docs) {
      try {
        setDocsToUpload(prev => {
          const update = [...prev];
          update[docIndex].status = STATUSES.uploading;
          return update;
        });
        await uploadFile(docs[docIndex].file);
        setDocsToUpload(prev => {
          const update = [...prev];
          update[docIndex].status = STATUSES.success;
          return update;
        });
      } catch (err) {
        setDocsToUpload(prev => {
          const update = [...prev];
          update[docIndex].status = STATUSES.error;
          return update;
        });
      }
    }
    setIsUploadingDocs(false);
    setDocsToUpload([]);
    setIsLoading(true);
    const updateDocList = await documentsApi.getDocuments(match.params.carId, match.params.userId);
    setDocList(updateDocList);
    setIsLoading(false);
  }

  const getUploadIcon = (status) => {
    switch (status) {
      case STATUSES.error:
        return <IconWarning color="#D84747" />;
      case STATUSES.success:
        return <IconCheckCircle color="#39B64A" />;
      case STATUSES.uploading:
        return <Spinner color="success" style={{height: '20px', width: '20px'}} />;
      case STATUSES.staged:
      default:
        return <IconAdd />;
    }
  };

  return (
    <div className="vehicle-documents">
      <h2>Documents</h2>
      <Button className="ml-4 mb-4" color="primary" onClick={() => setShowUploads(!showUploads)}>
        Upload files
      </Button>
      <Collapse className="pl-4" isOpen={showUploads}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="drag-n-drop-area">
            Drag 'n' drop files here, or click to select files
          </div>
        </div>
        {!!docsToUpload.length && (
          <div className="staged-uploads">
            <ul className="list-unstyled">
              {docsToUpload.map((doc, i) => <li key={i}>{doc.file.name}</li>)}
            </ul>
            <div className="text-right">
              <Button color="primary" onClick={uploadFiles}>
                Save
              </Button>
            </div>
          </div>
        )}
      </Collapse>
      {isLoading ? (
        <div style={{position: 'relative'}}>
          <Loader />
        </div>
      ) : (
        <>
          <BootstrapTable
            remote
            keyField="_id"
            data={docList}
            columns={columns}
            hover
            striped
            condensed
            noDataIndication={t('No documents.')}
            bordered={false}
          />
        </>
      )}
      <Modal isOpen={isUploadingDocs}>
        <ModalHeader>Uploading documents</ModalHeader>
        <ModalBody>
          <ul className="list-unstyled">
            {docsToUpload.map((doc, i) => (
              <li key={i} className="d-flex my-2">
                <div className="flex-grow-1">{doc.file.name}</div>
                <div>{getUploadIcon(doc.status)}</div>
              </li>
            ))}
          </ul>
        </ModalBody>
      </Modal>
      <Modal isOpen={pendingDeleteId !== null} centered>
        <ModalHeader>Are you sure you want to delete the document?</ModalHeader>
        <ModalBody className="d-flex align-items-end justify-content-end">
          <Button className="mr-3" color="primary" outline onClick={closeDeleteModal}>Cancel</Button>
          <Button color="danger" onClick={deleteDocument}>Confirm</Button>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default VehicleDocuments;
