import IconTrash from './IconTrash';
import IconPencil from './IconPencil';
import IconCheckmark from './IconCheckmark';
import IconAdd from './IconAdd';
import IconSearch from './IconSearch';
import IconBuilding from './IconBuilding';
import IconBank from './IconBank';
import IconBell from './IconBell';
import IconMobileMenu from './IconMobileMenu';
import IconVerticalMenu from './IconVerticalMenu';
import IconToolBox from './IconToolBox';
import IconUser from './IconUser';
import IconEmail from './IconEmail';
import IconRemove from './IconRemove';
import IconDocument from './IconDocument';
import IconCircleArrows from './IconCircleArrows';
import IconDollar from './IconDollar';
import IconLock from './IconLock';
import IconUnlock from './IconUnlock';
import IconWarning from './IconWarning';
import IconSortArrowUp from './IconSortArrowUp';
import IconSortArrowDown from './IconSortArrowDown';
import IconPrint from './IconPrint';
import IconPhone from './IconPhone';
import IconLogout from './IconLogout';
import IconArrowLeft from './IconArrowLeft';
import IconSendEmail from './IconSendEmail';
import IconCheckCircle from './IconCheckCircle';

export { IconTrash, IconPencil, IconCheckmark, IconAdd, IconSearch, IconBuilding, IconBank, IconBell, IconMobileMenu, IconVerticalMenu, IconToolBox, IconUser, IconEmail, IconRemove, IconDocument, IconCircleArrows, IconDollar, IconLock, IconUnlock, IconWarning, IconSortArrowUp, IconSortArrowDown, IconPrint, IconPhone, IconLogout, IconArrowLeft, IconSendEmail, IconCheckCircle }