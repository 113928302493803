import React, { useEffect } from 'react';
import '../../App.scss';
import Footer from './Footer';
import { Container } from 'reactstrap';
import { Route, withRouter } from 'react-router-dom';
import Login from '../login/Login';
import LoginSupport from '../login/LoginSupport';
import classNames from "classnames";
import loadable from '@loadable/component';
import { LoginCallback } from '@okta/okta-react';
import RouteWhenMemberOfAny from '../general/RouteWhenMemberOfAny';
import VehicleDocuments from '../vehicles/VehicleDocuments';
import { useAuthContext } from '../../Auth';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../store/actions/user.action';
import BulkInvite from '../invitations/BulkInvite';

const HomePage = loadable(() => import('../../pages/HomePage'));
const VehicleList = loadable(() => import('../vehicles/VehicleList'));
const ActivateUser = loadable(() => import('../login/ActivateUser'));
const BulkInvitation = loadable(() => import('../invitations/BulkInvitation'));
const SendInvitation = loadable(() => import('../invitations/SendInvitations'));
const Dashboard = loadable(() => import('../../pages/Dashboard'));
const DummyPages = loadable(() => import('../general/DummyPages'));
const UserProfile = loadable(() => import('../user/UserProfile'));
const MessagePortal = loadable(() => import('../messageportal/MessagePortal'));

function Content(props) {

  const { sidebarIsOpen, excludeLocations } = props;

  const authContext = useAuthContext();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserGroup = async () => {
      await dispatch(fetchUser());
    };
    if (authContext.isAuthenticated) {
      fetchUserGroup();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.isAuthenticated]);

  return (
    <Container className={classNames("content", { "is-open": sidebarIsOpen }, "d-flex", "flex-wrap", "justify-content-center", "p-0", "mw-100")} >
      <div className="w-100 p-0 inner-content content-container" >
        <RouteWhenMemberOfAny groups={[]} exact path="/" render={({ component }) => component()} component={Dashboard}></RouteWhenMemberOfAny>
        <RouteWhenMemberOfAny groups={[]} exact path="/vehicles" render={({ component }) => component()} component={VehicleList}></RouteWhenMemberOfAny>
        <RouteWhenMemberOfAny groups={[]} exact path="/bulk-invitation" render={({ component }) => component()} component={BulkInvite}></RouteWhenMemberOfAny>
        <RouteWhenMemberOfAny groups={[]} exact path="/invitation" render={({ component }) => component()} component={SendInvitation}></RouteWhenMemberOfAny> 
        <RouteWhenMemberOfAny groups={[]} exact path="/analytics" render={({ component }) => component()} component={DummyPages}></RouteWhenMemberOfAny>
        <RouteWhenMemberOfAny groups={[]} exact path="/messageportal" render={({ component }) => component()} component={MessagePortal}></RouteWhenMemberOfAny>
        <RouteWhenMemberOfAny groups={[]} exact path="/promotions" render={({ component }) => component()} component={DummyPages}></RouteWhenMemberOfAny>
        <RouteWhenMemberOfAny groups={[]} exact path="/user-profile" render={({ component }) => component()} component={DummyPages}></RouteWhenMemberOfAny>
        <RouteWhenMemberOfAny groups={[]} exact path="/vehicles/:carId/docs/:userId" render={({ component }) => component()} component={VehicleDocuments}></RouteWhenMemberOfAny>
        <RouteWhenMemberOfAny groups={[]} exact path="/user-profile" render={({ component }) => component()} component={UserProfile}></RouteWhenMemberOfAny>
        <Route path="/login" component={Login} />
        <Route path="/login-support" component={LoginSupport} />
        <Route path="/implicit/callback" component={LoginCallback}></Route>
        <Route path="/activate/:activationToken?" component={ActivateUser} />
        
      </div>
     

    </Container>

  );
}

export default withRouter(Content);
