import React from 'react';
import '../../scss/components/layout/_profile_menu.scss';
import { useTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import classNames from "classnames";
import { Nav, NavItem, NavLink, Input } from 'reactstrap';
import { withAuthContext } from '../../Auth';
import { useOktaAuth } from '@okta/okta-react';
import { IconLogout } from '../../assets/icons';


function ProfileMenu(props) {

    const { t, i18n } = useTranslation();
    const { location, isOpen, toggle, excludeLocations } = props;

    const { oktaAuth } = useOktaAuth();
    const logout = () => {
        oktaAuth.signOut('/');
        toggle();
    }

    // const profilePages = useSelector(selectProfileMenuPages);

    let currentLanguage = i18n.language;
    if (currentLanguage && currentLanguage.length > 2) {
        currentLanguage = currentLanguage.substring(0, 2);
    }

    const supportedLanguages = [{ "value": "en", "text": "English" }];
    const showLanguageSelect = supportedLanguages && supportedLanguages.length > 1;

    const setLanguage = (e) => {
        localStorage.setItem('i18nextLng', e.currentTarget.value);
        window.location.reload(false);
    }

    let isActivate = excludeLocations.some(function (loc) { return location.pathname.startsWith(loc); });

    return (
        !(excludeLocations.includes(location.pathname) || isActivate) ?

            <>
                <div className={classNames("profile-menu-mask", { "is-open": isOpen })} onClick={toggle}></div>

                <div className={classNames("profile-menu-container", "shadow", { "is-open": isOpen })} onClick={toggle} >
                    <div className="profile-menu clearfix">
                        <Nav vertical className="list-unstyled pb-3">

                            <NavItem>
                                <NavLink tag={Link} to={"/user-profile"} onClick={toggle}>
                                    {t('Profile')}
                                </NavLink>
                            </NavItem> 

                            {/* {profilePages && profilePages.map((p) =>
                                p.groups.length === 0 || authContext.isUserInAnyGroups(p.groups) ?
                                    <NavItem key={p.id}>
                                        <NavLink tag={Link} to={p.clientRoute} onClick={toggle}>
                                            {t(p.title)}
                                        </NavLink>
                                    </NavItem> : '')
                            } */}

                            <NavItem>
                                <NavLink tag={Link} to={"/support"} onClick={toggle}>
                                    {t('Support & Tutorials')}
                                </NavLink>
                            </NavItem>
                            {showLanguageSelect ?
                                <NavItem>
                                    <Input type="select" onClick={e => { e.stopPropagation() }} defaultValue={currentLanguage} onChange={setLanguage}>
                                        {supportedLanguages.map(l =>
                                            <option key={l.value} value={l.value}>{l.text}</option>
                                        )}
                                    </Input>
                                </NavItem> : null}

                            <NavItem>
                                <NavLink className="text-uppercase" tag={Link} to={"/"} onClick={logout}>
                                    <i className="mr-2"><IconLogout /></i>
                                    {t('Logout')}
                                </NavLink>
                            </NavItem>

                        </Nav>

                    </div>
                </div>
            </>
            : ''
    );
}


export default withAuthContext(withRouter(ProfileMenu));
