import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import SupportForm from '../general/SupportForm';
import { Link } from 'react-router-dom';
import '../../scss/login/_login.scss';

class LoginSupport extends Component {

  render() {
    const { t } = this.props;

    return (
      <div className="login login-support">
        {/* <div className="login-mask"></div>  */}
        <div className="login-footer">&copy; {new Date().getFullYear()} {t('Assurant, Inc. All Rights Reserved')}</div>
        <div className="okta-wrapper">
          <div id="okta-sign-in" className="auth-container main-container d-block">
            <div className="okta-sign-in-header auth-header login-support-header">
              <img src="../images/assurant-logo.png" className="auth-org-logo" alt="" />
            </div>
            <div className="auth-content">
              <div className="auth-content-inner">
                <h2 data-se="o-form-head" className="okta-form-title o-form-head login-support-title">Support</h2>

                <SupportForm props={this.props} />

                <div className="auth-footer login-support-footer">
                  <Link to="/login" className="link help js-back" data-se="back-link">Back to Sign In</Link>
                </div>

              </div>
            </div>

          </div>

        </div>
      </div>
    )

  }
};

export default withTranslation()(LoginSupport);
