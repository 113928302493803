import api from '.';

const REPORT_URL = `/admin/report`;

const getCreditUnionReports = async (params, dispatch) => {
  return api.get(
    `${REPORT_URL}/creditUnionOnCarsReport`,
    params
  );
};

const getInvitationLogs = async (params, dispatch) => {
  return api.get(
    `${REPORT_URL}/invitationLogs`,
    params
  );
};

const getInvitationsStatus = async (params, dispatch) => {
  return api.get(
    `${REPORT_URL}/invitationsStatus`,
    params
  );
};

export default {
  getCreditUnionReports,
  getInvitationLogs,
  getInvitationsStatus,
};
