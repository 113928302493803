import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/styles';
import { DataGrid } from '@material-ui/data-grid';
import {
  Button,
  Grid,
  LinearProgress,
  Dialog,
  CircularProgress,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  TableContainer,
  Popover,
  Collapse,
  Icon
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { format, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { PAGINATION_OPTIONS } from '../../constants';
import invitationApi from '../../api/invitation.api';
import BulkInvitationForm from './BulkInvitationForm';
import InvitationLogs from './InvitationLogs';
import { useAuthContext } from '../../Auth';
import useWindowDimensions from '../general/WindowDimensionsHook';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { Close } from '@material-ui/icons';
import reportApi from '../../api/report.api';
import { grey } from '@material-ui/core/colors';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const INBOX_TABLE_FIELDS = [
  {
    display: 'Dealer Manager',
    id: "userName",
    width: 15,
    render: (e) => e.userName ? e.userName : '',
  },
  {
    display: 'Dealer ID',
    id: "dealerId",
    filter: true,
    width: 15,
    render: (e) => e.dealerId ? e.dealerId : '',
  },
  {
    display: 'Channels',
    id: "channel",
    width: 10,
    render: (e) => e.channels && Array.isArray(e.channels) ? e.channels.toString() : ''
  },
  {
    display: 'Created At',
    id: "createdAt",
    width: 10,
    render: (e) => e.createdAt ? format(parseISO(e.createdAt), "MM/dd/yyyy") : '',
  },
  {
    id: "status",
    display: 'Status',
    width: 10,
    render: (e) => e.processedRows ? `${e.processedRows} out of ${e.parsedRows} invited` : '',
  }
];

const INBOX_TABLE_DETAIL_FIELDS = [
  {
    display: 'Customer Name',
    id: "request",
    width: 15,
    render(e) {
      const roundColorFlag = (
        <div className='color-dot-row-invitation-detail-wrapper'>
          <div style={{
            flex: "0 0 auto",
            width: "50%", height: '15px',
            width: '15px', backgroundColor: `${(JSON.parse(e.response).statusCode == 400) ? 'red' : 'green'}`,
            borderRadius: '50%',
            display: 'inline-block'
          }}>
            <div style={{ marginLeft: "20px" }}>{JSON.parse(e.request).firstName} {JSON.parse(e.request).lastName}</div></div></div>
      );
      return (roundColorFlag)
    }
  },
  {
    display: 'Reason of the Failure',
    id: "response",
    filter: true,
    width: 15,
    render: (e) => e.response ? JSON.parse(e.response).statusCode == 400 ? JSON.parse(e.response).body : '' : '',
  },
  {
    display: 'VIN',
    id: "vin",
    width: 10,
    render: (e) => e.vin ? e.vin : ''
  },
  {
    display: 'Email',
    id: "email",
    width: 10,
    render: (e) => e.email ? e.email : '',
  },
  {
    id: "phone",
    display: 'Phone',
    width: 10,
    render: (e) => e.phone ? e.phone : '',
  },
  {
    id: "invitationSource",
    display: 'Invitation Source',
    width: 10,
    render: (e) => e.invitationSource ? e.invitationSource : '',
  },
  {
    id: "response",
    display: 'Response',
    width: 10,
    render(e) {
      return (JSON.parse(e.response).statusCode == 200 ? e.response ?
        <img width='30' height='30' src={`data:image/png;base64,${JSON.parse(e.response).body}`} /> :
        '' : '')
    }
  }
];






const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const BulkInvitation = () => {
  const dispatch = useDispatch();
  const dealerId = useSelector(((state) => state.currentUser && state.currentUser.selectedDealer ? state.currentUser.selectedDealer : null));
  const [purchaseDate, setPurchaseDate] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [toastOpen, setToastOpen] = useState(false);
  const [openInvitationLogs, setOpenInvitationLogs] = useState(false);
  const [logsFilter, setLogsFilter] = useState({});
  const [currentJobId, setCurrentJobId] = useState(null);
  const [jobStatus, setJobStatus] = useState({});
  const [openJobStatus, setOpenJobStatus] = useState(false);
  const authContext = useAuthContext();
  const { height, width } = useWindowDimensions();

  const useStyles = makeStyles({

    root: {
      width: '100%',
      height: '100%',
      borderBottom: '1px solid #dcdcdc',
    },
    container: {
      maxHeight: (height - 200),

    },
    fixheight: {
      height: (height - 200),
    }
  });
  const classes = useStyles();

  useEffect(() => {

    const fetchData = async (skip = (page) * pageSize, limit = pageSize) => {
      const filters = { ...purchaseDate && { createdAt: purchaseDate }, dealerId };
      if (typeof purchaseDate == "string" && purchaseDate.length > 0) {
        let endDate = new Date(purchaseDate)
        endDate.setUTCHours(23, 59, 0, 0);
        filters.createdAt = { "$gte": purchaseDate, "$lte": endDate.toISOString() }
      }
      const params = {
        skip,
        limit,
        filter: JSON.stringify(filters)
      };

      return invitationApi.getBulkInvitation(params);
    };
    const loadData = async () => {
      setIsLoading(true);
      const reports = await fetchData();
      if (reports && reports.data) {
        setRawData(reports)
        setDataSource(reports.data);
        setRowCount(reports.totalCount)
      }
      setIsLoading(false);
    };
    if (authContext.isAuthenticated) {
      loadData();
    }
  }, [authContext.isAuthenticated, page, pageSize, purchaseDate, dealerId]);

  const addToDataSourceAtStart = (data) => {
    if (!data || !data._id) {
      return;
    }
    const updateDataSource = dataSource.map((item) => {
      // eslint-disable-next-line no-param-reassign
      item._gridId++;
      return item;
    });
    // eslint-disable-next-line no-param-reassign
    data._gridId = 0;
    setDataSource([data, ...updateDataSource]);
    setToastOpen(true);
  };

  const handleModalClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenModal(false);
    }
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const getBulkInvitationStatus = async (jobId) => {
    if (jobId && jobStatus._id) {
      if (jobStatus._id === jobId) {
        return;
      }
      setJobStatus({});
    }
    const response = await invitationApi.getBulkInvitationStatus(
      { jobId },
      dispatch
    );
    if (response && response.length === 1) {
      setJobStatus(response[0]);
    } else if (response && response.length === 0) {
      setJobStatus({ _id: jobId });
    } else {
      setJobStatus({ _id: jobId, error: true });
    }
  };

  const handleJobStatusClose = () => {
    setOpenJobStatus(false);
  };

  const handleCellClick = (cellParams) => {
    if (cellParams.field === 'jobId') {
      setOpenInvitationLogs(true);
      setCurrentJobId(cellParams.value);
      setLogsFilter({
        bulkInviteRef: {
          $eq: cellParams.value,
        },
      });
    } else if (cellParams.field === 'totalRows') {
      setOpenJobStatus(true);
      getBulkInvitationStatus(cellParams.row.jobId);
    }
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastOpen(false);
  };

  const handleInvitationLogsClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenInvitationLogs(false);
    }
  };

  return (
    <Grid container className="reports p-3">

      <Grid item sm={12}>
        <div
          style={{ height: '60vh', width: '100%', overflowY: "scroll" }}
          className={classes.root}
        >
          <div className='search-invite-datebutton-container'>
            <DatePickerPopover purchaseDate={purchaseDate} setPurchaseDate={setPurchaseDate} />
          </div>
          {/* <DataGrid
              pageSize={pageSize}
              page={page}
              pagination
              rowsPerPageOptions={PAGINATION_OPTIONS}
              onPageSizeChange={handlePageSizeChange}
              rowCount={rowCount || 0}
              getRowId={(row) => row._gridId}
              rows={dataSource}
              columns={columns}
              onPageChange={handlePageChange}
              onCellClick={handleCellClick}
            /> */}
          <Paper className={[classes.root, 'flex-column']}>
            <TableContainer className={classes.container + ' flex-1'} >
              <Table
                style={{ height: (rawData && rawData.data) ? '100%' : '100%' }}
                stickyHeader>
                <TableHead>
                  <TableRow>
                    {
                      dataSource.length > 0
                        ?
                        INBOX_TABLE_FIELDS.map((field) => (
                          <TableCell
                            width={`${field.width}%`}
                            key={field.display}
                            align="left"
                            className="p-2 table-header-text gray-bg"
                            component="th"
                            style={field.styles}
                          >
                            <div className="table-display-name">{field.display}</div>
                          </TableCell>
                        ))
                        :
                        <TableCell
                          className="p-2 table-header-text gray-bg"
                          component="th"
                        >
                          No invitations have been sent yet.
                        </TableCell>
                    }
                  </TableRow>
                </TableHead>
                {
                  !isLoading
                    ?
                    <TableBody className={classes.container} style={{ height: '100%' }}>
                      {
                        dataSource.map((row, index) => {
                          return <InviteRow key={index} rowIndex={index} data={row} />
                        })
                      }
                    </TableBody>
                    :
                    <TableBody className={classes.fixheight} style={{ height: '100%' }}>
                      <TableRow >
                        <TableCell style={{ textAlign: "center" }} colSpan={10}>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                }
              </Table>
            </TableContainer>
          </Paper>

        </div>
        {
          rawData && rawData.data &&
          <TablePagination
            rowsPerPage={pageSize}
            component="div"
            count={rawData.totalCount
              ? rawData.totalCount
              : rawData.data.length * rawData.pageCount}
            page={rawData.currentPage - 1}
            rowsPerPageOptions={PAGINATION_OPTIONS}
            onChangeRowsPerPage={handlePageSizeChange}
            onChangePage={handlePageChange} />
        }

        <Dialog
          onClose={handleModalClose}
          aria-labelledby="customized-dialog-title"
          open={openModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleModalClose}>
            Create Bulk Invite
          </DialogTitle>
          <DialogContent>
            <BulkInvitationForm
              closeDialog={handleModalClose}
              updateListData={addToDataSourceAtStart}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          onClose={handleInvitationLogsClose}
          aria-labelledby="customized-dialog-title"
          open={openInvitationLogs}
          fullWidth
          maxWidth="lg"
          disableEnforceFocus
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleInvitationLogsClose}
          >
            {currentJobId ? `Invite Logs for Job Id: ${currentJobId}` : ''}
          </DialogTitle>
          <DialogContent style={{ minHeight: '80vh' }}>
            <InvitationLogs
              filter={logsFilter}
              jobStatus={
                jobStatus._id ===
                  (logsFilter.bulkInviteRef && logsFilter.bulkInviteRef.$eq)
                  ? jobStatus
                  : {}
              }
              getBulkInvitationStatus={getBulkInvitationStatus}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          onClose={handleJobStatusClose}
          aria-labelledby="customized-dialog-title"
          open={openJobStatus}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleJobStatusClose}
          >
            Value
          </DialogTitle>
          <DialogContent>
            {jobStatus && Object.keys(jobStatus).length === 0 ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            ) : jobStatus && jobStatus.error ? (
              'Error in fetching detailed status'
            ) : (
              <div>
                <div>
                  Total Rows In File:&nbsp;
                  {jobStatus.totalRows}
                </div>
                <div>
                  Parsed Rows:&nbsp;
                  {jobStatus.parsedRows}
                </div>
                <div>
                  Processed Rows:&nbsp;
                  {jobStatus.processedRows}
                </div>
                <div>
                  Sent Invitation:&nbsp;
                  {jobStatus.success}
                </div>
                <div>
                  Failed Invitation:&nbsp;
                  {jobStatus.failure}
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>
        <Snackbar
          open={toastOpen}
          autoHideDuration={6000}
          onClose={handleToastClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleToastClose} severity="success">
            Bulk Invite Created Successfully
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};


const InviteRow = ({ data }) => {
  const [open, isOpen] = useState(false);

  return (
    <>
      <TableRow style={{ height: 55, backgroundColor: (open) ? "#F8FAFB" : "#fff" }}>
        {
          INBOX_TABLE_FIELDS.map((field) => (
            <TableCell
              key={field.display}
              align="left"
              scope="row"
              className="p-2 table-field-text" >
              <div
                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                onClick={() => {
                  isOpen(!open)
                }}>
                {
                  field.render(data)
                }
              </div>
            </TableCell>
          ))
        }
      </TableRow>
      <TableRow style={{ backgroundColor: (open) ? "#F8FAFB" : "#fff" }} >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} unmountOnExit>
            <div style={{ marginTop: "26px", flexWrap: 'wrap', width: '100%' }} >
              <div style={{ float: 'right' }}>
                <IconButton className="row-close-btn"
                  onClick={() => {
                    isOpen(!open)
                  }}>
                  <Close />
                </IconButton>
              </div>
              <div className='color-dot-row-invitation-detail-wrapper'>
                <div style={{
                  margin: "10px",
                  flex: "0 0 auto",
                  width: "50%", height: '15px',
                  width: '15px', backgroundColor: 'green',
                  borderRadius: '50%',
                  display: 'inline-block'
                }}><div style={{ marginLeft: "30px" }}>Success</div></div>
                <div style={{
                  margin: "10px",
                  marginLeft: "80px",
                  flex: "0 0 auto",
                  width: "50%",
                  height: '15px',
                  width: '15px',
                  backgroundColor: 'red',
                  borderRadius: '50%',
                  display: 'inline-block'
                }}><div style={{ marginLeft: "30px" }}>Failure</div></div>
              </div>
            </div>
            <InviteDetailTable jobId={data.jobId} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}


const InviteDetailTable = ({ jobId }) => {
  const [isLoadingDetail, setIsLoadingDetail] = useState(true);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[0]);
  const [page, setPage] = useState(0);
  const [rawDataDetail, setRawDataDetail] = useState(null);
  const [dataSourceDetail, setDataSourceDetail] = useState([]);


  const handlePageSizeDetailChange = (event, value) => {
    setPageSize(value.props.value);
    setPage(0)
  };

  const handlePageDetailChange = (event, value) => {
    setPage(value);
  };


  useEffect(() => {
    getInvitaionDetails()
  }, [page, pageSize])


  const getInvitaionDetails = () => {
    const fetchData = async (skip = (page) * pageSize, limit = pageSize) => {
      const params = {
        skip,
        limit,
        filter: JSON.stringify({ "bulkInviteRef": { "$eq": jobId } })
      };
      return reportApi.getInvitationLogs(params);
    };

    const loadData = async () => {
      setIsLoadingDetail(true);
      const details = await fetchData();
      if (details && details.data) {
        setRawDataDetail(details)
        setDataSourceDetail(details.data);
        // setRowCountDetail(details.totalCount)
      }
      setIsLoadingDetail(false);
    };
    loadData()

  };


  return (
    <>
      <TableContainer >
        <Table >
          <TableHead>
            <TableRow>
              {
                dataSourceDetail.length > 0
                  ?
                  INBOX_TABLE_DETAIL_FIELDS.map((field) => (
                    <TableCell
                      width={`${field.width}%`}
                      key={field.display}
                      align="left"
                      className="p-2 table-header-text gray-bg"
                      component="th"
                      style={field.styles}
                    >
                      <div className="table-display-name">{field.display}</div>
                    </TableCell>
                  ))
                  :
                  <TableCell
                    className="p-2 table-header-text gray-bg"
                    component="th"
                  >
                    {!isLoadingDetail ? 'Invitation log unavailable.' : 'Fetching Invitation Log'}
                  </TableCell>
              }
            </TableRow>
          </TableHead>
          {
            !isLoadingDetail
              ?
              <TableBody >
                {
                  dataSourceDetail.map((row, index) => {
                    return <InviteDetailRow key={index} rowIndex={index} data={row} />
                  })
                }
              </TableBody>
              :
              <TableBody >
                <TableRow >
                  <TableCell style={{ textAlign: "center" }} colSpan={10}>

                    <CircularProgress />
                  </TableCell>

                </TableRow>
              </TableBody>
          }
        </Table>
      </TableContainer>
      {
        rawDataDetail && rawDataDetail.data &&
        <TablePagination
          rowsPerPage={pageSize}
          component="div"
          count={rawDataDetail.totalCount
            ? rawDataDetail.totalCount
            : rawDataDetail.data.length * rawDataDetail.pageCount}
          page={rawDataDetail.currentPage - 1}
          rowsPerPageOptions={PAGINATION_OPTIONS}
          onChangeRowsPerPage={handlePageSizeDetailChange}
          onChangePage={handlePageDetailChange} />
      }
    </>
  );
}


const InviteDetailRow = ({ data }) => {
  const [open, isOpen] = useState(false);
  let rowShade = { ...data % 2 == 0 }
  return (
    <>
      <TableRow style={{ backgroundColor: `${rowShade ? '#fff' : '#666'}`, height: 45 }}>
        {
          INBOX_TABLE_DETAIL_FIELDS.map((field) => (
            <TableCell
              key={field.id}
              align="left"
              scope="row"
              className="p-2 table-field-text" >
              {
                field.render(data)
              }
            </TableCell>
          ))
        }
      </TableRow>
    </>
  )


}

const DatePickerPopover = ({ purchaseDate, setPurchaseDate }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    console.log(state)
  }, [state])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSubmit = () => {

    if (state[0].startDate == state[0].endDate) {
      let startDate = new Date(state[0].startDate)
      startDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)
      setPurchaseDate(startDate.toISOString())
    } else {
      let startDate = new Date(state[0].startDate)
      let endDate = new Date(state[0].endDate)
      startDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)
      endDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000)
      endDate.setUTCHours(23, 59, 0, 0);
      setPurchaseDate({ "$gte": startDate.toISOString(), "$lte": endDate.toISOString() })
    }

    handleClose()
  }

  const handleClear = () => {
    setPurchaseDate('')
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ])
    handleClose()
  }

  const buttonName = () => {
    if (typeof purchaseDate == "object") {
      let str = new Date(purchaseDate["$gte"]).toDateString()
      if (purchaseDate["$lte"]) {
        str += " - " + new Date(purchaseDate["$lte"]).toDateString()
      }
      return str;
    } else if (purchaseDate.length != 0) {
      return new Date(purchaseDate).toDateString()
    }
    else {
      return "Search By Invited Date"
    }
  }

  return (
    <>
      <div>
        <Button
          color="link"
          className={open ? 'date-picker-active' : 'date-picker-button'} aria-describedby={id} variant="contained" onClick={handleClick}>
          {
            buttonName()
          }

        </Button>
        {purchaseDate != "" &&
          <IconButton onClick={handleClear}>
            <Close />
          </IconButton>
        }
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{ marginBottom: 10 }}>
          <DateRangePicker
            onChange={item => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            ranges={state}

          />
          <div className='date-picker-select-btn-container'>
            <Button onClick={handleClear} color='link' className='date-picker-select-btn' >Clear</Button>
            <Button onClick={handleSubmit} color='link' className='date-picker-select-btn' >Select</Button>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default BulkInvitation;