import api from '.';

const STATUS_URL = `/status`;

const getVersion = async () =>
  api.publicApiGet(`${STATUS_URL}/version`);

const getAPIStatus = async (dispatch) =>
  api.publicApiGet(`${STATUS_URL}/api`);

const getUserGroups = async (disptch) =>
  api.get('/dealer/user/groups');

const getDashboardData = async (params) => {
  return await api.get('/dealer/dashboardData', params);
}

export default {
  getVersion,
  getAPIStatus,
  getUserGroups,
  getDashboardData
};
