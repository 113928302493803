import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import BulkInvitation from './BulkInvitation';
import BulkInviteForm from './BulkInviteForm';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#0066CC',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    fontWeight: '600',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    '&:hover': {
      color: '#0066CC',
    },
    '&$selected': {
      color: '#0066CC',
      outline: 0,
    },
    '&:focus': {
      color: '#40a9ff',
    },
    minWidth: '300px',
    textTransform: 'none',
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor:"#fff"
  },
}));

export default function InvitePanel() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root + " ml-4 pb-2"}>
      <div style={{ backgroundColor: 'white' }}>
        <AntTabs value={value} onChange={handleChange}>
          <AntTab
            label="Send Bulk Invite"
            {...a11yProps(0)}
          />
          <AntTab
            label="Invitation Status"
            {...a11yProps(1)}
          />
        </AntTabs>
      </div>
      <TabPanel value={value} index={0}>
        <BulkInviteForm />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BulkInvitation />
      </TabPanel>
    </div>
  );
}
