/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useStore, useDispatch } from 'react-redux';
import './BulkInvite.scss';
import { Spinner } from 'reactstrap';
import Dropzone from 'react-dropzone';
import fileUploadApi from '../../api/fileUpload.api';
import invitationApi from '../../api/invitation.api';
import { uploadIcon, uploadSuccess, binIcon } from '../../images';
import { IconButton, Snackbar } from '@material-ui/core';
import ColoredLinearProgress from '../vehicles/vehicleProgressTheme';
import { IconWarning } from '../../assets/icons';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const validator = {
  email: (value) => {
    return value
      ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
      : true;
  },
  phoneNumber: (value) => value && /^(\+1)?([0-9]{10})$/.test(value),
  vin: (value) => value && value.length && /^[A-Z0-9]{17}$/.test(value),
};

const NO_DATA_IN_CSV = 'No data found in the uploaded csv.';
const COLUMNS_MISMATCH = 'Uploaded csv does not contain the required columns.';
const CSV_FILE_ONLY = 'Please Upload a csv file only.';

function BulkInviteForm() {
  const store = useStore();
  const [bulkFile, setBulkFile] = useState(null);
  const [validFile, setValidFile] = useState(true);
  const [fileFeedback, setFileFeedback] = useState('');
  const [numberOfRecords, setNumberOfRecords] = useState(0);
  const [sendingInvite, setSendingInvite] = useState(false);
  const dispatch = useDispatch();
  const [formFields, setFormFields] = useState({
    emailChannel: false,
    smsChannel: false,
  });
  const [toastOpen, setToastOpen] = useState(false);
  const [showError, setShowError] = useState(false);

  const getPayload = (fileUrl) => {
    const fileName = encodeURI(bulkFile.name);
    const regex = new RegExp(
      `(bulk-invitations-jobs/)(.*)(/${fileName})`,
      'gi'
    );
    const matches = regex.exec(fileUrl);
    const dealerId = store.getState().currentUser
      ? store.getState().currentUser.selectedDealer
      : '';
    let filePath = '';
    let jobId = '';
    if (matches && matches.length === 4) {
      [filePath, , jobId] = matches;
    }
    const channels = [];
    if (formFields.emailChannel) {
      channels.push('EMAIL');
    }
    if (formFields.smsChannel) {
      channels.push('SMS');
    }
    return {
      filePath,
      jobId,
      channels,
      dealerId,
      totalRows: numberOfRecords,
    };
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!bulkFile) {
      setShowError(true);
      return;
    }
    if (!validFile) {
      return;
    }
    setSendingInvite(true);
    const fileUrl = await fileUploadApi.getUploadUrl({
      type: 'bulkInvitation',
      parameters: {},
      imageName: bulkFile ? bulkFile.name : '',
      contentType: bulkFile.type,
    });
    // uploadToS3
    await fileUploadApi.uploadToS3(fileUrl, bulkFile, bulkFile.type);
    const payload = getPayload(fileUrl);
    const response = await invitationApi.sendBulkInvitation(payload, dispatch);
    resetState();
    if (response) {
      setToastOpen(true);
    }
  };

  const resetState = () => {
    setSendingInvite(false);
    setBulkFile(null);
    setFormFields({
      emailChannel: false,
      smsChannel: false,
    });
    setNumberOfRecords(0);
  }

  const isAllColPresent = (columns) => {
    const inviteColumns = [
      'name',
      'vin',
      'email',
      'phoneNumber',
      'dealerId',
      'address1',
      'address2',
      'city',
      'state',
      'zipCode',
      'country',
      'smsEnabled',
      'emailEnabled',
    ];
    const col = inviteColumns.find((item, index) => {
      return item !== columns[index];
    });
    return !(col && col.length);
  };

  const checkCsvValidaity = (fileData) => {
    if (!fileData || fileData.length === 0) {
      setFileFeedback(NO_DATA_IN_CSV);
      return false;
    }
    const columns = fileData[0].split(',');
    if (!isAllColPresent(columns)) {
      setFileFeedback(COLUMNS_MISMATCH);
      return false;
    }
    const colIndex = columns.reduce((acc, item, index) => {
      acc[item] = index;
      return acc;
    }, {});
    let totalRecords = 0;
    for (let itr = 1; itr < fileData.length; itr++) {
      const row = fileData[itr].split(',');
      if (row && row.length === columns.length) {
        const entry = Object.entries(validator).find(([key, value]) => {
          const index = colIndex[key];
          return !value(row[index]);
        });
        if (entry && entry.length) {
          setFileFeedback(
            `Value: '${row[colIndex[entry[0]]]}' provided for column: '${entry[0]
            }' is not valid.`
          );
          return false;
        }
        totalRecords++;
      } else if (row.length === 1 && row[0] === '') {
        // continue
      } else {
        setFileFeedback(
          `Row: '${itr}' does not have values for all the columns.`
        );
        return false;
      }
    }
    if (totalRecords === 0) {
      setFileFeedback(NO_DATA_IN_CSV);
      return false;
    }
    setNumberOfRecords(totalRecords);
    return true;
  };

  const onFileUpload = (files) => {
    if (files && files.length) {
      const fileName = files[0].name;
      if (fileName.split('.').pop() !== 'csv') {
        setValidFile(false);
        setFileFeedback(CSV_FILE_ONLY);
        return;
      }
      const reader = new FileReader();
      reader.onloadend = (e) => {
        setBulkFile(files[0]);
        if (
          e.target.result &&
          e.target.result.length &&
          checkCsvValidaity(e.target.result.split(/\r?\n/))
        ) {
          setValidFile(true);
        } else {
          setValidFile(false);
        }
      };
      reader.readAsText(files[0]);
    }
  };

  useEffect(() => {
    if (bulkFile && showError) {
      setShowError(false);
    }
  }, [bulkFile])

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastOpen(false);
  };

  const handleInputChange = (event) => {
    const { name } = event.target;
    let { value } = event.target;
    const cloneForm = { ...formFields };

    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }
    cloneForm[name] = value;
    setFormFields(cloneForm);
  };

  const disableSendButton = () => sendingInvite || !bulkFile || !validFile;

  const handleDelete = (event) => {
    event.stopPropagation();
    setBulkFile(null);
  }

  return (
    <div
      className="container bulk-invite-container p-3 m-0"
      style={{ maxWidth: '680px' }}
    >
      <div className="row m-0 py-3">
        <h4>What to Expect</h4>
        <p>
          As you upload your file here, we will send email to your client on
          your behalf. That invitation will consist a deep link/QR code for
          client to download the world class POCKET GEEK AUTO app on their
          devices. You can view a sample of invitation&nbsp;
          <a
            href="/sample-invitation.pdf"
            style={{
              textDecoration: 'underline',
              fontWeight: 'bold',
              cursor: 'pointer',
              color: '#0070b9'
            }}
            download
          >
            here.
          </a>
        </p>
      </div>
      <div className="row bulk-upload-section m-0 py-3">
        <div>
          <p>
            Please Upload a CSV file only. You can upload the file or drag file
            into the zone.
          </p>
          <p>
            You can view a sample CSV&nbsp;
            <a
              href="/Bulk-Invitations-Sample.csv"
              style={{
                textDecoration: 'underline',
                fontWeight: 'bold',
                cursor: 'pointer',
                color: '#0070b9',
              }}
              download
            >
              here.
            </a>
          </p>
        </div>
        <div className="bulk-drag-n-drop-area">
          <Dropzone onDrop={onFileUpload} accept=".csv" multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <section className="bulk-drag-drop-section">
                <div {...getRootProps()} className="bulk-drag-drop-area">
                  <input accept=".csv" {...getInputProps()} />
                  <p className="bulk-drag-drop-text-1">
                    Drag and drop file here or
                  </p>
                  <div className="bulk-drag-drop-upload-btn-container">
                    <button
                      className="btn bulk-upload-btn"
                      tabIndex="0"
                      type="button"
                    >
                      <span className="MuiButton-label">
                        <span className="MuiButton-startIcon MuiButton-iconSizeMedium">
                          <img
                            className="bulk-upload-btn-icon"
                            src={uploadIcon}
                            alt=""
                          />
                        </span>
                        Upload File
                      </span>
                      <span className="MuiTouchRipple-root" />
                    </button>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <div className="bulk-checkbox-section">
          <div>Invitation Channel</div>

          <div className="bulk-checkbox form-check">
            <label style={{ color: formFields.emailChannel ? 'inherit' : 'grey' }}>
              <input
                type="checkbox"
                id="emailChannel"
                name="emailChannel"
                checked={formFields.emailChannel}
                onChange={handleInputChange}
              />
              Email
            </label>
          </div>
          <div className="bulk-checkbox">
            <label style={{ color: formFields.smsChannel ? 'inherit' : 'grey' }}>
              <input
                type="checkbox"
                id="smsChannel"
                name="smsChannel"
                checked={formFields.smsChannel}
                onChange={handleInputChange}
              />
              SMS
            </label>
          </div>
        </div>
        {bulkFile && (
          <div className='px-5 py-4'>
            <div>CSV File</div>
            <div className='uploading-document' >
              <div className='d-flex justify-content-between w-100 my-2 align-items-center'>
                <div className='uploading-documents-name'>
                  {bulkFile.name}
                </div>
                <div>
                  {
                    validFile && <img src={uploadSuccess} />
                  }
                  {
                    !validFile && <IconWarning color="#B90000" />
                  }
                  <IconButton onClick={handleDelete}><img src={binIcon} /></IconButton>
                </div>
              </div>

              <div className='w-100'>
                <ColoredLinearProgress color={validFile ? "primary" : "secondary"} variant="determinate" value={100} />
                {
                  !validFile && <div style={{ color: "#B90000" }}>{fileFeedback}</div>
                }
              </div>
            </div>
          </div>
        )}

      </div>
      <div className="row m-0 p-2">
        Note: Once your file is processed it&apos;s immediately deleted, we do
        not store a copy of this file.
        {!bulkFile && showError && (
          <div style={{ color: "#B90000" }}>
            * Please Upload a CSV file before proceeding.
          </div>
        )}
      </div>
      <div className="row m-0 p-2 justify-content-end">
        <button
          type="submit"
          className={disableSendButton() ? "btn btn-outline-mediumgray" : "btn btn-outline-darkgray"}
          style={{ borderRadius: '25px' }}
          onClick={handleFormSubmit}
          disabled={disableSendButton()}
        >
          {!sendingInvite ? (
            'Send Invite'
          ) : (
            <>
              <span>Sending Invite&nbsp;</span>
              <Spinner color="dark" style={{ height: '25px', width: '25px' }} />
            </>
          )}
        </button>
      </div>
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleToastClose} severity="success">
          Bulk Invite Created Successfully
        </Alert>
      </Snackbar>
    </div>
  );
}

export default BulkInviteForm;
