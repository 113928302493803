import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

class ColoredLinearProgress extends Component {
  render() {
    const { classes,variant } = this.props;
    return <LinearProgress {...this.props} variant={variant}  classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary, barColorSecondary: classes.barColorSecondary }}/>;
  }
}

const styles = props => ({
  colorPrimary: {
    backgroundColor: '#ccc',
  },
  barColorPrimary: {
    backgroundColor: '#047D26',
  },
  barColorSecondary: {
    backgroundColor: '#B90000',
  },
});

export default  withStyles(styles)(ColoredLinearProgress);