import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updatedSelectedDealer } from '../../store/actions/userGroup.action';
import { FormGroup, Input } from 'reactstrap';
import { DEALERSLIST } from '../../constants';
import { useAuthContext } from '../../Auth';

const DealerDropDown = () => {
    const dispatch = useDispatch();
    const dealerId = useSelector((state) => state.currentUser.selectedDealer);
    const { userGroups: { dealers = DEALERSLIST } = {} } = useAuthContext();

    useEffect(() => {
        if (dealers && dealers.length && !dealerId) {
            updateCurrentDealer(dealers[0].id);
        }
    }, [dealers]);
    const onDealerChange = (e) => {
        updateCurrentDealer(e.currentTarget.value);
    };

    const updateCurrentDealer = (dealerId) => {
        // setDealerId(dealerId);
        dispatch(updatedSelectedDealer({ dealerId }));
    };

    return (
        <div style={{ width: 350, paddingTop: 20 }}>
            {dealers && dealers.length ?
                (
                    <FormGroup className="d-flex align-items-center ml-4">
                        <Input type="select" name="dealer-select" id="dealer-select" value={dealerId} onChange={onDealerChange}>
                            {dealers.map(({ dealerName, id }) => (
                                <option key={id} value={id}>{dealerName}</option>
                            ))}
                        </Input>
                    </FormGroup>
                ) : ""
            }
        </div>
    )
}
export default DealerDropDown;