import React from 'react';
import DealerDropDown from '../general/DealerDropDown';

const BodyContainer = (props) => {
    return (
        <div className='body-container'>
            <div >
                <DealerDropDown />
                <hr />
            </div>
            <div className='body-child-container'>
                {
                    props.children
                }
            </div>
        </div>
    )
}
export default BodyContainer;