import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../scss/components/layout/_sidebar.scss';
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import { Nav, NavLink, NavItem } from 'reactstrap';
import { useAuthContext } from '../../Auth';
import { GroupAdd, PersonAdd } from '@material-ui/icons';
import BusinessCenterOutlined from '@material-ui/icons/BusinessCenterOutlined';
import PersonOutline from '@material-ui/icons/PersonOutline';
import MailOutline from '@material-ui/icons/MailOutline';
import Assessment from '@material-ui/icons/Assessment';
import LocalOffer from '@material-ui/icons/LocalOffer';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Footer from './Footer';


const SideBar = (props) => {
  const t = useTranslation().t;
  const location = useLocation();
  const [width, setWidth] = useState('');
  const pages = [{
    id: '123',
    clientRoute: '/',
    groups: [],
    icon: <DashboardIcon />,
    title: 'Dashboard'
  },{
    id: '124',
    clientRoute: '/messageportal',
    groups: [],
    icon: <MailOutline />,
    title: 'Message Portal'
  },
  {
    id: '125',
    clientRoute: '/analytics',
    groups: [],
    icon: <Assessment />,
    title: 'Analytics And Reporting'
  },
  {
    id: '126',
    clientRoute: '/user-profile',
    groups: [],
    icon: <PersonOutline />,
    title: 'Dealer\'s Profile'
  },
  {
    id: '127',
    clientRoute: '/promotions',
    groups: [],
    icon: <LocalOffer />,
    title: 'Promotions'
  },
  {
    id: '128',
    clientRoute: '/vehicles',
    groups: [],
    icon: <BusinessCenterOutlined />,
    title: 'Glovebox'
  },
  {
    id: '130',
    clientRoute: '/invitation',
    groups: [],
    icon: <PersonAdd />,
    title: 'Invitation'
  },
  {
    id: '131',
    clientRoute: '/bulk-invitation',
    groups: [],
    icon: <GroupAdd />,
    title: 'Bulk Invitation'
  }]

  const authContext = useAuthContext();

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnSidebarIcon = (iconName) => {
    return <span>{iconName || 'T'}</span>
  }

  const updateWindowDimensions = () => {
    if (width !== window.innerWidth) {
      setWidth(window.innerWidth);
    }
  }

  const { isOpen, excludeLocations } = props;
  const isMobile = width <= 767;

  let isActivate = excludeLocations.some(function (loc) { return location.pathname.startsWith(loc); });

  return !(excludeLocations.includes(location.pathname) || isActivate) ? (
    <div
      className={classNames(
        "sidebar",
        { "is-open": isMobile ? !isOpen : isOpen },
        "overflow-auto"
      )}
    >
      <div className="side-menu side-menu-container">

        <Nav vertical className="list-unstyled pb-3">
          {pages && pages.map(p =>
            (p.groups.length === 0 || authContext.isUserInAnyGroups(p.groups)) &&
              <NavItem key={p.id}>
                <NavLink tag={Link} to={p.clientRoute} className="text-white" active={p.clientRoute === location.pathname}>
                  <div className="h-100 d-flex align-content-center">
                    <i>{returnSidebarIcon(p.icon)}</i>
                    <span>{p.title}</span>
                    {/* TODO: Add support for nested nav items if p.children contains any items*/}</div>
                </NavLink>
              </NavItem>
          )}

        </Nav>
        <Footer
          sidebarIsOpen={isOpen}
          excludeLocations={excludeLocations}
        />
      </div>
    </div>
  ) : (
      ""
    );

}

export default SideBar;

