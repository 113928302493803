import React from "react";

function IconCheckCircle(props) {

    let color = props.color ? props.color : "";
    let height = props.height ? props.height : "20";

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 24 24"
            version="1.1"
            viewBox="0 0 24 24"
            xmlSpace="preserve"
            height={height}
        >
            <path fill={color} d="M9.325 17.916a1.79 1.79 0 01-1.498-.799l-2.44-3.462a.75.75 0 011.227-.864l2.45 3.477a.306.306 0 00.261.148.294.294 0 00.236-.119l7.852-9.935a.75.75 0 011.175.93l-7.843 9.923a1.781 1.781 0 01-.423.396 1.78 1.78 0 01-.997.305z"></path>
            <path fill={color} d="M12 23.999c-6.617 0-12-5.383-12-12s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12zm0-22.5c-5.79 0-10.5 4.71-10.5 10.5s4.71 10.5 10.5 10.5 10.5-4.71 10.5-10.5-4.71-10.5-10.5-10.5z"></path>
        </svg>
    );
}

export default IconCheckCircle;
